import React from "react";
import { Link } from "react-router-dom";
import { AnimationLogin } from "../../assets/animations";
import { Button, Card, Layout } from "../../components";

export default function Home() {
    return (
        <Layout video={AnimationLogin}>
            <Card color="blue">
                <h2>Login by MIA</h2>
                <p className="texto login home">
                    Experimentá un nueva forma de validar la identidad de tus
                    clientes, con toda la seguridad e innovación de MIA.
                </p>
                <div className="buttons-container">
                    <Button type="primary" color="blue">
                        <Link to="/login/qr">Ingresar con QR</Link>
                    </Button>
                    <Button type="primary" color="blue">
                        <Link to="/login/paso-1">
                            Ingresar con notificación
                        </Link>
                    </Button>
                </div>
            </Card>
        </Layout>
    );
}
