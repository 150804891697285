import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BOArrow, BOBanner, ImageIdentityModal2, Success } from "../../assets";
import { IdentityLayout, Mapa } from "../../components";
import { GlobalContext } from "../../Context";
import { ModalTable, Button } from "../../components";
import TablaFotografia from "./TablaFotografia";
import { Grid } from "@mui/material";

import classes from "./Usuario.module.scss";

export default function Usuario() {
    const { userInfo } = useContext(GlobalContext);
    const [coord, setCoord] = useState({});
    const [open, setOpen] = useState(false);
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoord({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                });
            },
            (error) => console.log(error)
        );
    }, []);
    console.log(userInfo);

    return (
        <IdentityLayout>
            <div className={classes.main}>
                <ModalTable
                    open={open}
                    setOpen={setOpen}
                    text="Resultados del análisis de calidad de la imagen de la selfie"
                >
                    <TablaFotografia />
                </ModalTable>

                <Grid
                    container
                    className={classes.gridContainer}
                    paddingX={{ xl: "7em" }}
                >
                    <div className={classes.upperContainer}>
                        <Grid
                            item
                            xs={5}
                            md={4}
                            lg={3}
                            flexWrap={"wrap"}
                            justifyContent={{
                                xs: "flex-start",
                                md: "space-between",
                            }}
                            marginLeft={"2em"}
                            className={classes.imgContainer}
                        >
                            <img
                                alt="Selfie"
                                className={classes.imageUsuario}
                                src={userInfo?.imagePathSELFIE}
                            />
                            <div className={classes.imagenes}>
                                <img
                                    src={userInfo?.imagePathDNIFrente}
                                    alt="Frente"
                                />
                                <img
                                    src={userInfo?.imagePathDNIDorso}
                                    alt="Dorso"
                                />
                            </div>
                        </Grid>

                        <Grid
                            item
                            xs={4}
                            sm={5}
                            md={6}
                            lg={4}
                            className={classes.columns}
                        >
                            <div className={classes.column}>
                                <div>
                                    <label htmlFor="">Nombre y apellido</label>
                                    <p>
                                        {userInfo?.usuarioNombres +
                                            " " +
                                            userInfo?.usuarioApellidos}
                                    </p>
                                </div>
                                <div>
                                    <label htmlFor="">
                                        Numero de documento
                                    </label>
                                    <p>{userInfo?.usuarioNroDeDocumento}</p>
                                </div>
                                <div>
                                    <label htmlFor="">
                                        Fecha de nacimiento
                                    </label>
                                    <p>{userInfo?.usuarioFechaDeNacimiento}</p>
                                </div>
                                <div>
                                    <label htmlFor="">Sexo</label>
                                    <p>
                                        {userInfo?.usuarioSexo === "F"
                                            ? "Femenino"
                                            : "Masculino"}
                                    </p>
                                </div>
                            </div>

                            <div className={classes.column}>
                                <div>
                                    <label htmlFor="">
                                        Fecha de emisión DNI
                                    </label>
                                    <p>
                                        {userInfo?.usuarioDocumentoFechaEmision}
                                    </p>
                                </div>
                                <div>
                                    <label htmlFor="">
                                        Fecha de vencimiento DNI
                                    </label>
                                    <p>1/2/2035</p>
                                </div>
                                <div>
                                    <label htmlFor="">Nro de tramite</label>
                                    <p>
                                        {
                                            userInfo?.usuarioDocumentoNumeroTramite
                                        }
                                    </p>
                                </div>
                                <div>
                                    <label htmlFor="">
                                        Transacción del evento
                                    </label>
                                    <p>
                                        {moment().format(
                                            "DD/MM/YYYY hh:mm:ss a"
                                        )}
                                    </p>
                                </div>
                            </div>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={3}
                            xl={4}
                            marginTop={{ xs: "1.5em", lg: "0" }}
                        >
                            <div className={classes.mapaContainer}>
                                <div className={classes.mapDiv}>
                                    <Mapa
                                        lat={coord.lat}
                                        lng={coord.lng}
                                        style={{
                                            width: "100%",
                                            height: 280,
                                            marginBottom: 28,
                                        }}
                                    />
                                </div>

                                <div className={classes.mapCoordinates}>
                                    <div className={classes.coordenadas}>
                                        <label>Provincia</label>
                                        <p>Buenos Aires</p>
                                    </div>
                                    <div className={classes.coordenadas}>
                                        <label>Localidad</label>
                                        <p>Buenos Aires</p>
                                    </div>
                                    <div className={classes.coordenadas}>
                                        <label>Latitud</label>
                                        <p>{coord.lat?.toFixed(6)}</p>
                                    </div>
                                    <div className={classes.coordenadas}>
                                        <label>Longitud</label>
                                        <p>{coord.lng?.toFixed(6)}</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </div>

                    <hr />

                    <Grid item sm={12}>
                        <div className={classes.scores}>
                            <div>
                                <img src={Success} alt="Check" />
                                <h4>Geofence</h4>
                                <p>98%</p>
                            </div>
                            <div>
                                <img src={Success} alt="Check" />
                                <h4>D.N.I</h4>
                                <p>{userInfo?.usuarioNroDeDocumento}</p>
                            </div>
                            <div
                                className={classes.fotografia}
                                onClick={() => setOpen(true)}
                            >
                                <img src={Success} alt="Check" />
                                <h4>Fotografia</h4>
                                <p>
                                    {Math.round(userInfo?.scoreSelfie * 100) /
                                        100}
                                </p>
                            </div>
                            <div>
                                <img
                                    src={
                                        userInfo?.scoreLiveness > 0.4
                                            ? Success
                                            : ImageIdentityModal2
                                    }
                                    alt="Check"
                                />
                                <h4>Liveness</h4>
                                <p>{userInfo?.scoreLiveness?.toFixed(4)}</p>
                            </div>
                            <div>
                                <img
                                    src={
                                        userInfo?.antispoofingFMR <
                                        0.000026046557
                                            ? Success
                                            : ImageIdentityModal2
                                    }
                                    alt="Check"
                                />
                                <h4>Antispoofing</h4>
                                <p>
                                    {(1 - userInfo?.scoreAntispoofing).toFixed(
                                        4
                                    )}
                                </p>
                            </div>
                            <div>
                                <img src={Success} alt="Check" />
                                <h4>Identity level</h4>
                                <p>0.8%</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <div className={classes.bannerContainer}>
                    <img
                        className={classes.banner}
                        src={BOBanner}
                        alt="banner"
                    />
                    <img
                        className={classes.bannerArrow}
                        src={BOArrow}
                        alt="arrow"
                    />
                </div>

                <div className={classes.buttonContainer}>
                    <Button color="orange-strong" type="primary">
                        <Link to="/cierre">Finalizar demo</Link>
                    </Button>
                </div>
            </div>
        </IdentityLayout>
    );
}
