import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Activos, Baja, Pendientes, Suspendidos } from "../../../assets";
import classes from "./LandingCard.module.scss";

const statusInicial = {
    activos: 0,
    pendientes: 0,
    suspendidos: 0,
    baja: 0,
    rechazados: 0,
};

export default function LandingCard({ usuarios, type, title, image, link }) {
    const navigate = useNavigate();
    const handleClick = () => navigate(link);
    const [active, setActive] = useState(null);

    const handleHover = (newActive) => () => setActive(newActive);
    const handleBlur = () => setActive(null);

    const servicios = useMemo(() => {
        const seen = new Object();
        return usuarios.filter((item) => {
            /* eslint-disable-next-line */
            return seen.hasOwnProperty(item.servicioNombre)
                ? false
                : (seen[item.servicioNombre] = true);
        });
    }, [usuarios]);

    const usuariosStatus = useMemo(() => {
        return usuarios.reduce((acc, item) => {
            if (item.servicioId !== active && active !== null) return acc;
            const newAcc = { ...acc };
            if (item.relacionStatus === "Activo") newAcc.activos++;
            if (item.relacionStatus === "Pendiente") newAcc.pendientes++;
            if (item.relacionStatus === "Baja") newAcc.baja++;
            if (item.relacionStatus === "Rechazada") newAcc.rechazados++;
            if (item.relacionStatus === "Suspendido") newAcc.suspendidos++;
            return newAcc;
        }, statusInicial);
    }, [usuarios, active]);

    return (
        <div className={`${classes.card} ${classes[type]}`}>
            <div className={classes.total} onClick={handleClick}>
                <img src={image} alt={title} />
                <h2>{title}</h2>
                <h4>{usuarios.length}</h4>
            </div>
            <div>
                {servicios.map((u) => (
                    <div
                        key={u.servicioId}
                        className={classes.servicio}
                        onMouseEnter={handleHover(u.servicioId)}
                        onMouseLeave={handleBlur}
                    >
                        {u.servicioNombre}
                    </div>
                ))}
            </div>
            <div className={classes.parciales}>
                {usuariosStatus.activos > 0 && (
                    <div className={classes.parcial}>
                        <img src={Activos} alt="Activos" />
                        <p>Activos:</p>
                        <h4>{usuariosStatus.activos}</h4>
                    </div>
                )}
                {usuariosStatus.pendientes > 0 && (
                    <div className={classes.parcial}>
                        <img src={Pendientes} alt="Pendientes" />
                        <p>Pendientes:</p>
                        <h4>{usuariosStatus.pendientes}</h4>
                    </div>
                )}
                {usuariosStatus.suspendidos > 0 && (
                    <div className={classes.parcial}>
                        <img src={Suspendidos} alt="Suspendidos" />
                        <p>Suspendidos:</p>
                        <h4>{usuariosStatus.suspendidos}</h4>
                    </div>
                )}
                {usuariosStatus.rechazados > 0 && (
                    <div className={classes.parcial}>
                        <img src={Baja} alt="Rechazado" />
                        <p>Rechazados:</p>
                        <h4>{usuariosStatus.rechazados}</h4>
                    </div>
                )}
                {usuariosStatus.baja > 0 && (
                    <div className={classes.parcial}>
                        <img src={Baja} alt="Baja" />
                        <p>De baja:</p>
                        <h4>{usuariosStatus.baja}</h4>
                    </div>
                )}
            </div>
        </div>
    );
}
