import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { GlobalContext } from "../../Context";
import { Link } from "react-router-dom";
import moment from "moment";
// Assets
import { IDLFace, Plane } from "../../assets";
// Services
import {
    clienteUsuarioGet,
    loginWebCheck,
    loginWebRequest,
    usuarioFotoGet,
    usuarioMinimosGet,
} from "../../services";
// Components
import { Button, Card, Layout, Spinner } from "../../components";

export default function Notificacion() {
    const { merchant, userInfo, setUserInfo, setToastInfo } =
        useContext(GlobalContext);

    const [coord, setCoord] = useState();
    const [esperando, setEsperando] = useState(false);
    const [webToken, setWebToken] = useState("");
    const [usuarioImagen, setUsuarioImagen] = useState();
    const intentos = useRef(0);

    const url = new URLSearchParams(window.location.search);
    const dni = url.get("dni");

    const doLogin = useCallback(async () => {
        if (!coord || !dni) return;
        setEsperando(true);
        const response = await loginWebRequest(
            dni,
            coord.lat.toFixed(6),
            coord.lng.toFixed(6)
        );
        setWebToken(response.errmsg);
        setEsperando(true);
    }, [coord, dni]);

    useEffect(() => {
        setUserInfo(null);
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoord({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                });
            },
            (error) => console.log(error)
        );
    }, [setUserInfo]);

    useEffect(() => {
        doLogin();
    }, [doLogin]);

    useEffect(() => {
        if (!esperando) return;

        const checkLogin = setInterval(async () => {
            if (!coord || !dni || !webToken) return;
            intentos.current++;

            const response = await loginWebCheck(
                dni,
                webToken,
                coord.lat.toFixed(6),
                coord.lng.toFixed(6)
            );
            if (response.status.code === 0 && intentos.current >= 60) {
                clearInterval(checkLogin);
                return setToastInfo({
                    open: true,
                    text: response.status.errmsg,
                });
            }
            if (response.status.code === 1 && merchant) {
                setEsperando(false);
                const user = await usuarioMinimosGet(response.result[0]);
                const usuario = (
                    await clienteUsuarioGet(
                        merchant.merchantId,
                        null,
                        user.usuarioId
                    )
                ).filter((u) => u.relacionStatus === "Activo")[0];
                if (!usuario) return setUserInfo(null);

                const imagenUsuario = await usuarioFotoGet(user.usuarioId);
                setUsuarioImagen(imagenUsuario);
                clearInterval(checkLogin);
                return setUserInfo({ ...user, ...usuario });
            }
        }, 3000);

        return () => clearInterval(checkLogin);
    }, [coord, webToken, dni, merchant, esperando, setUserInfo, setToastInfo]);

    if (userInfo)
        return (
            <Layout>
                <Card color="blue">
                    <img
                        src={
                            usuarioImagen?.imagePathPERFIL ||
                            usuarioImagen?.imagePathSELFIE ||
                            IDLFace
                        }
                        alt="Usuario"
                        className="imagen-perfil"
                    />
                    <h2>¡Hola, {userInfo.usuarioNombres}!</h2>
                    <p>
                        Sos un usuario, conectado a{" "}
                        <b>{merchant?.merchantRZ}</b> como{" "}
                        <b>
                            {userInfo.rolNombre} con el servicio{" "}
                            {userInfo.servicioNombre}.
                        </b>
                    </p>
                    <p>
                        Fecha de nacimiento:{" "}
                        <b>
                            {moment(userInfo.usuarioFechaDeNacimiento).format(
                                "DD/MM/YYYY"
                            )}
                        </b>
                    </p>
                    <p>
                        Sexo:{" "}
                        <b>
                            {userInfo.usuarioSexo === "M"
                                ? "Masculino"
                                : userInfo.usuarioSexo === "F"
                                ? "Femenino"
                                : "-"}
                        </b>
                    </p>
                    <p>
                        Email: <b>{userInfo.usuarioMail || "-"}</b>
                    </p>
                    <p>
                        Telefono:{" "}
                        <b>{userInfo.usuarioTelefonoCelular || "-"}</b>
                    </p>
                    <Button type="primary" color="blue">
                        <Link to="/cierre">Finalizar</Link>
                    </Button>
                </Card>
            </Layout>
        );

    return (
        <Layout>
            <Card color="blue">
                <img src={Plane} alt="Enviado" />
                <h2>¡Notificación enviada!</h2>
                <p>
                    Para poder ingresar, deberá abrir la notificación que
                    enviamos a través de la App de MIA y validar su identidad
                </p>
                <Spinner />
                <Button type="primary" color="blue">
                    <Link to="/demo">Cancelar</Link>
                </Button>
            </Card>
        </Layout>
    );
}
