import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AnimationUserSponsorship } from "../../assets/animations";
import { Button, Card, Layout, Spinner } from "../../components";
import QRCode from "react-qr-code";
import { LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { clienteUsuarioGet, loginQREvent, loginQRGet, usuarioMinimosGet } from "../../services";
import { QRCaducado } from "../../assets";
import { GlobalContext } from "../../Context";

export default function Home() {
    const { merchant, setUserInfo, setToastInfo } =
        useContext(GlobalContext);
    const navigate = useNavigate();

    const [UUID, setUUID] = useState();
    const [loading, setLoading] = useState(true);
    const [expirado, setExpirado] = useState(false);
    const [coord, setCoord] = useState();
    const [progress, setProgress] = useState(0);
    const intentos = useRef(0);

    const handleError = useCallback(
        (text) => {
            setToastInfo({ open: true, text });
            setLoading(false);
        },
        [setToastInfo]
    );

    const getQR = useCallback(async () => {
        if (!coord) return;
        setExpirado(false);
        setLoading(true);
        const data = await loginQRGet(
            coord.lat.toFixed(6),
            coord.lng.toFixed(6)
        );
        if (!data) return handleError("Ha ocurrido un error inesperado");
        setProgress(0);
        setUUID(data.codigoQR);
        setLoading(false);
    }, [coord, handleError]);

    const getQREvent = useCallback(async () => {
        if (!UUID) return;

        const response = await loginQREvent(UUID);

        if (response.status.code === 1 && merchant) {
            const user = await usuarioMinimosGet(response.result[0]);
            const usuario = (
                await clienteUsuarioGet(
                    merchant.merchantId,
                    null,
                    user.usuarioId,
                    "MA"
                )
            ).filter((u) => u.relacionStatus === "Activo")[0];
            if (usuario) {
                navigate('/user-sponsorship/paso-1');
            } else {
                handleError('Usuario no autorizado');
            }
        }

        intentos.current++;
        setUUID(null);
        intentos.current < 2 ? getQR() : setExpirado(true);
    }, [getQR, setUserInfo, UUID, merchant]);

    useEffect(() => {
        setUserInfo(null);
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoord({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                });
            },
            (error) => console.log(error)
        );

        const timer = setInterval(() => {
            setProgress((oldProgress) =>
                oldProgress + 60 / 580 >= 100
                    ? 100
                    : oldProgress + ((60 / 580) * 100) / 60
            );
        }, (60 / 580) * 1000);

        return () => {
            clearInterval(timer);
        };
    }, [setUserInfo]);

    useEffect(() => {
        getQR();
    }, [getQR]);

    useEffect(() => {
        getQREvent();
    }, [getQREvent]);

    return (
        <Layout video={AnimationUserSponsorship}>
            <Card color="blue">
                <h2>User Sponsorship</h2>
                <p className="texto sponsorship home">
                    Validá tu identidad para invitar a tus colaboradores a que se vinculen a tu organización.
                </p>
                {expirado ? (
                    <>
                        <img src={QRCaducado} alt="QR caduco" />
                        <Button type="primary" color="blue" onClick={getQR}>
                            Generar QR
                        </Button>
                    </>
                ) : (
                    loading || !UUID ?
                        <Spinner />
                        :
                        <>
                            <QRCode value={UUID} color={"#171b37"} size={200} />
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                                style={{
                                    width: "200px",
                                    marginTop: "16px",
                                    color: "#171b37",
                                }}
                            />
                        </>
                )}
            </Card>
        </Layout>
    );
}
