import React from "react";
import { Success } from "../../../assets";

import classes from "./ValidationBadges.module.scss";

export const ValidationBadges = ({ success }) => {
    const { antispoofing, liveness, fotografia } = success;
    return (
        <div className={classes.validationContainer}>
            <span className={classes.successBox}>
                <img src={Success} alt="success" />
                <p id={classes.bannersSuccess}>D.N.I</p>
            </span>
            {
                fotografia ? (
                    <span className={classes.successBox}>
                        <img src={Success} alt="success" />
                        <p id={classes.bannersSuccess}>Fotografía</p>
                    </span>
                ) : (
                    <p id={classes.bannersPending}>Fotografía</p>
                )
            }


            <span className={classes.successBox}>
                <img src={Success} alt="success" />
                <p id={classes.bannersSuccess}>Identity level</p>
            </span>


            {
                liveness ? (
                    <span className={classes.successBox}>
                        <img src={Success} alt="success" />
                        <p id={classes.bannersSuccess}>Liveness detection</p>
                    </span>
                ) : (
                    <p id={classes.bannersPending}>Liveness detection</p>
                )
            }

            {
                antispoofing ? (
                    <span className={classes.successBox}>
                        <img src={Success} alt="success" />
                        <p id={classes.bannersSuccess}>Antispoofing</p>
                    </span>
                ) : (
                    <p id={classes.bannersPending}>Antispoofing</p>
                )
            }

            <span className={classes.successBox}>
                <img src={Success} alt="success" />
                <p id={classes.bannersSuccess}>Geofence</p>
            </span>
        </div>
    );
};
