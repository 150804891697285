import { transformDate, formatNumber } from ".";

export const generateColumns = (titles) => {
    return titles.reduce((acc, title) => {
        const col = {
            field: title,
            headerName: title,
            headerAlign: "left",
            headerClassName: "header-bold",
            valueGetter: (params) => {
                if (params.value === null || params.value === undefined)
                    return "";
                if (/Fecha/.test(title)) return transformDate(params.value);
                if (/DNI/.test(title))
                    return new Intl.NumberFormat("es-ES").format(params.value);
                if (/^\d(.*\d)?$/.test(params.value))
                    return formatNumber(params.value);
                return params.value;
            },
            flex: /Sexo|Fecha|DNI|Estado/.test(title) ? 0 : 1,
            width: /Sexo|Fecha|DNI|Estado/.test(title) ? 120 : 0,
        };
        return [...acc, col];
    }, []);
};
