import React from "react";

import classes from "./Header.module.scss";
import { IsologoHeader, NewLogoMia } from "../../../assets";

export default function Header() {
    return (
        <>
            <header className={classes.header}>
                <div className={classes.containerMiaLogo}>
                    <img
                        src={NewLogoMia}
                        alt="new logo"
                        className={classes.newLogoMia}
                    />
                </div>
                <img
                    src={IsologoHeader}
                    alt="isologo"
                    className={classes.isologo}
                />
            </header>
        </>
    );
}
