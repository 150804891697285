import axiosPost from "./axiosPost";

export const connectionIns = async (
    uuid,
    merchantId,
    usuarioId,
    DNI,
    tipoRelacion,
    rolId,
    servicioId
) => {
    const body = {
        service: "MIADemoConnectionIns",
        params: {
            conexionUUID: uuid,
            merchantId: merchantId,
            usuarioId: usuarioId,
            usuarioNroDeDocumento: DNI,
            merchantServicioUsuarioTiporel: tipoRelacion,
            rolId: rolId,
            servicioHooliId: servicioId,
        },
    };

    const result = await axiosPost(body);
    return result.status;
};
