import React from "react";
import { LogoMiaLayout } from "../../assets";
import { Button } from "..";
import classes from "./Modal.module.scss";

export default function Modal(props) {
    const { open, setOpen, text, handleClick, color, children } = props;
    if (!open) return <></>;

    return (
        <div className={classes.container}>
            <div className={classes.modal}>
                <img src={LogoMiaLayout} alt="MIA" />
                <p>{text}</p>
                <div>{children}</div>
                <div className="buttons-container">
                    <Button
                        type="primary"
                        color={color ? color : "purple"}
                        onClick={() => setOpen(false)}
                        text="No"
                    />
                    <Button
                        type="primary"
                        color={color ? color : "purple"}
                        onClick={handleClick}
                        text="Sí"
                    />
                </div>
            </div>
        </div>
    );
}
