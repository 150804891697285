import axiosPost from "./axiosPost";

export const loginQRGet = async (lat, lng) => {
    const body = {
        service: "LoginQRGet",
        params: {
            sesionLatitud: lat,
            sesionLongitud: lng,
        },
    };

    const result = await axiosPost(body);
    return result.result[0];
};
