import React, { useContext } from "react";
import classes from "./TablaFotografia.module.scss";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { GlobalContext } from "../../Context";
export default function TablaFotografia() {
    const { qualityAssessment } = useContext(GlobalContext);
    console.log("Quality assesment", qualityAssessment);
    const renderIcon = (condition) => {
        return (
            <>
                {condition ? (
                    <CheckIcon color="success" />
                ) : (
                    <CloseIcon color="error" />
                )}
            </>
        );
    };
    return (
        <section className={classes.section}>
            {/**Table 1 */}
            <table>
                <thead>
                    <tr>
                        <th> Global Face Quality</th>
                        <th> Face Found </th>
                        <th> Only One Face</th>
                        <th> Eyes Found </th>
                        <th> Eyes Open </th>
                        <th> Inter Eyes Distance </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.GlobalFaceQualityScore}{" "}
                                {renderIcon(
                                    qualityAssessment.GlobalFaceQualityPass
                                )}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.FaceFoundConfidence}{" "}
                                {renderIcon(qualityAssessment.FaceFoundPass)}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.OnlyOneFaceConfidence}{" "}
                                {renderIcon(qualityAssessment.OnlyOneFacePass)}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.EyesFoundConfidence}{" "}
                                {renderIcon(qualityAssessment.EyesFoundPass)}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.EyesOpenConfidence}{" "}
                                {renderIcon(qualityAssessment.EyesOpenPass)}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.InterEyesDistance}{" "}
                                {renderIcon(
                                    qualityAssessment.InterEyesDistancePass
                                )}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            {/**Table 2 */}
            <table>
                <thead>
                    <tr>
                        <th> Exposure</th>
                        <th> Uniform Ligthing </th>
                        <th> Sharpnees </th>
                        <th> Grayscale Density </th>
                        <th> Frontal Pose</th>
                        <th> Pose Angle Roll</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.Exposure}{" "}
                                {renderIcon(qualityAssessment.ExposurePass)}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.UniformLightingConfidence}{" "}
                                {renderIcon(
                                    qualityAssessment.UniformLightingPass
                                )}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.Sharpness}{" "}
                                {renderIcon(qualityAssessment.SharpnessPass)}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.GrayscaleDensity}{" "}
                                {renderIcon(
                                    qualityAssessment.GrayscaleDensityPass
                                )}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.FrontalPoseConfidence}{" "}
                                {renderIcon(qualityAssessment.FrontalPosePass)}
                            </div>
                        </td>

                        <td>
                            <div className={classes.tdGroup}>
                                {qualityAssessment.PoseAngleRoll}{" "}
                                {renderIcon(
                                    qualityAssessment.PoseAngleRollPass
                                )}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
}
