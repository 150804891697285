import axiosPost from "./axiosPost";

export const usuarioFichaGet = async (id) => {
    const body = {
        service: "MIADemoUsuarioFichaGet",
        params: {
            usuarioId: id,
        },
    };

    const result = await axiosPost(body);
    return result.result[0];
};
