import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LupaIcon, ReloadIcon } from "../../assets";
import {
    DashboardGrid,
    DashboardLayout,
    DashboardTableActionIcon,
    Spinner,
} from "../../components";
import { GlobalContext } from "../../Context";
import { clienteUsuarioGet } from "../../services";

const titles = [
    "Apellido y Nombre",
    "DNI",
    "Sexo",
    "Fecha de nac.",
    "Servicio",
    "Rol",
    "Fecha de solicitud",
    "Fecha de alta",
    "Estado",
];

export default function UserSponsorship() {
    const navigate = useNavigate();
    const { merchant } = useContext(GlobalContext);

    const [selected, setSelected] = useState(null);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const getUsers = useCallback(async () => {
        setLoading(true);
        const usuarios = await clienteUsuarioGet(merchant?.merchantId, "S");
        const newRows = usuarios.reduce((acc, usuario) => {
            const newRow = {
                id: usuario.merchantServicioUsuarioId,
                usuarioId: usuario.usuarioId,
                [titles[0]]: usuario.usuarioNombreCompleto,
                [titles[1]]: usuario.usuarioNroDeDocumento,
                [titles[2]]: usuario.usuarioSexo,
                [titles[3]]: usuario.usuarioFechaDeNacimiento,
                [titles[4]]: usuario.servicioNombre,
                [titles[5]]: usuario.rolNombre,
                [titles[6]]: usuario.usuarioFecha,
                [titles[7]]: usuario.usuarioFechaDeAlta,
                [titles[8]]: usuario.relacionStatus,
            };
            return [...acc, newRow];
        }, []);
        setRows(newRows);
        setLoading(false);
    }, [merchant]);

    useEffect(() => {
        getUsers();
        return () => {
            setSelected(null);
        };
    }, [getUsers]);

    const handleRedirect = () =>
        navigate(
            `/user-dashboard/usuario?usuario=${selected?.usuarioId}&tipo=S`
        );

    return (
        <DashboardLayout title="User sponsorship">
            <main>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        {selected && selected.usuarioId ? (
                            <DashboardTableActionIcon
                                icon={LupaIcon}
                                onClick={handleRedirect}
                            />
                        ) : (
                            <DashboardTableActionIcon
                                icon={ReloadIcon}
                                onClick={getUsers}
                            />
                        )}
                        <DashboardGrid
                            titles={titles}
                            rows={rows}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </>
                )}
            </main>
        </DashboardLayout>
    );
}
