import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context";
import { useNavigate } from "react-router-dom";
// Assets
import { IconDNI, IdentityIcon } from "../../assets";
// Components
import { Button, Card, Input, Layout, Spinner } from "../../components";
import { formatDNI } from "../../utils";

export default function Paso1() {
    // const { merchant, setUserInfo, setToastInfo } = useContext(GlobalContext);
    const { setUserInfo, setToastInfo } = useContext(GlobalContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [DNI, setDNI] = useState("");

    useEffect(() => {
        setLoading(false); // Borrar
        // setUserInfo(null);
    }, [setUserInfo]);

    // const handleError = (text: string) => {
    //     setToastInfo({ open: true, text });
    //     setLoading(false);
    // };

    const handleError = (text) => {
        setToastInfo({ text, open: true });
        setLoading(false);
    };

    const handleSubmit = () => {
        if (DNI === "") return handleError("Por favor ingrese un DNI");
        const formattedDNI = DNI.replace(/\./g, "");
        if (formattedDNI.length < 7 || formattedDNI.length > 8)
            return handleError("Por favor ingrese un DNI valido");
        navigate("/identity/validar");
    };

    const handleChange = (value) => formatDNI(value, setDNI);

    // const handleSubmit = async (): Promise<void> => {
    //     if (!merchant) return;
    //     if (DNI === '') return handleError('Por favor ingrese un DNI');
    //     if (DNI.length < 7 || DNI.length > 8) return handleError('Por favor ingrese un DNI valido');

    //     setLoading(true);

    //     // Revisa si el usuario existe
    //     const usuarioCheckResponse = await usuarioCheck(DNI);
    //     if (usuarioCheckResponse.code === 0) return handleError(usuarioCheckResponse.errmsg);

    //     // Si no existe navega a la pagina de no usuario
    //     if (usuarioCheckResponse.errmsg === "Usuario inexistente") return navigate(`/user-connection/no-usuario?dni=${DNI}`);

    //     const usuarioId = usuarioCheckResponse.id as number;

    //     // Si existe recupera los datos y navega a la pagina de usuario
    //     const usuarioFichaGetResponse = await usuarioFichaGet(usuarioId);
    //     setUserInfo({ ...usuarioFichaGetResponse } as any);
    //     return navigate('/user-connection/usuario');
    // };

    if (loading) {
        return (
            <Layout>
                <Card color="blue">
                    <Spinner />
                </Card>
            </Layout>
        );
    }

    return (
        <Layout>
            <Card color="blue">
                <img src={IdentityIcon} alt="Identity" />
                <h2>Identity Rail</h2>
                <p>
                    Ingresá tu D.N.I. para verificar si ya te registraste
                    previamente
                </p>
                <Input
                    name="dni"
                    label="DNI"
                    type="text"
                    placeholder="22334455"
                    value={DNI}
                    setValue={handleChange}
                    image={IconDNI}
                />
                <Button
                    type="primary"
                    color="blue"
                    text="Continuar"
                    onClick={handleSubmit}
                />
            </Card>
        </Layout>
    );
}
