import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalContext } from "./Context";
import "./App.scss";
import {
    Demo,
    Home,
    ProtectedRoute,
    Cierre,
    ConnectionHome,
    ConnectionPaso1,
    ConnectionQR,
    ConnectionNoUsuario,
    ConnectionUsuario,
    ConnectionRechazado,
    ConnectionAceptado,
    SponsorshipHome,
    SponsorshipPaso1,
    SponsorshipNoUsuario,
    SponsorshipQR,
    SponsorshipUsuario,
    SponsorshipUsuarioSinRol,
    SponsorshipAceptado,
    SponsorshipRechazado,
    DashboardHome,
    DashboardLanding,
    DashboardUserConnection,
    DashboardUserSponsorship,
    DashboardUsuario,
    LoginHome,
    LoginPaso1,
    LoginQR,
    LoginNotificacion,
    IdentityHome,
    IdentityPaso1,
    IdentityValidar,
    IdentityUsuario,
    IdentityCompra,
    GobiernoTucuman,
    LegajoTucuman,
    PortalEmpleadoTucuman,
} from "./pages";
import { Toast } from "./components";
// import { merchantGet } from './services';

export default function App() {
    const { merchant, userInfo } = useContext(GlobalContext);

    return (
        <Router>
            <Toast />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/demo" element={<Demo />} />
                <Route path="/cierre" element={<Cierre />} />
                <Route path="/gob-tucuman" element={<GobiernoTucuman />} />
                <Route path="/gob-tucuman-legajo" element={<LegajoTucuman />} />
                <Route
                    path="/gob-tucuman-empleado"
                    element={<PortalEmpleadoTucuman />}
                />
                {/* User connection */}
                <Route path="/user-connection" element={<ConnectionHome />} />
                <Route
                    path="/user-connection/paso-1"
                    element={<ConnectionPaso1 />}
                />
                <Route path="/user-connection/qr" element={<ConnectionQR />} />
                <Route
                    path="/user-connection/aceptado"
                    element={<ConnectionAceptado />}
                />
                <Route
                    path="/user-connection/rechazado"
                    element={<ConnectionRechazado />}
                />

                <Route
                    path="/user-connection/no-usuario"
                    element={
                        <ProtectedRoute
                            value={userInfo}
                            condition={false}
                            redirect="/user-connection/usuario"
                        >
                            <ConnectionNoUsuario />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/user-connection/usuario"
                    element={
                        <ProtectedRoute
                            value={userInfo}
                            condition={true}
                            redirect="/user-connection/paso-1"
                        >
                            <ConnectionUsuario />
                        </ProtectedRoute>
                    }
                />

                {/* User sponsorship */}
                <Route path="/user-sponsorship" element={<SponsorshipHome />} />
                <Route
                    path="/user-sponsorship/paso-1"
                    element={<SponsorshipPaso1 />}
                />
                <Route
                    path="/user-sponsorship/qr"
                    element={<SponsorshipQR />}
                />
                <Route
                    path="/user-sponsorship/aceptado"
                    element={<SponsorshipAceptado />}
                />
                <Route
                    path="/user-sponsorship/rechazado"
                    element={<SponsorshipRechazado />}
                />

                <Route
                    path="/user-sponsorship/no-usuario"
                    element={
                        <ProtectedRoute
                            value={userInfo}
                            condition={false}
                            redirect="/user-sponsorship/usuario"
                        >
                            <SponsorshipNoUsuario />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/user-sponsorship/usuario"
                    element={
                        <ProtectedRoute
                            value={userInfo}
                            condition={true}
                            redirect="/user-sponsorship/paso-1"
                        >
                            <SponsorshipUsuario />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/user-sponsorship/usuario-sin-rol"
                    element={
                        <ProtectedRoute
                            value={userInfo}
                            condition={true}
                            redirect="/user-sponsorship/paso-1"
                        >
                            <SponsorshipUsuarioSinRol />
                        </ProtectedRoute>
                    }
                />

                {/* Login */}

                <Route path="/login" element={<LoginHome />} />
                <Route path="/login/qr" element={<LoginQR />} />
                <Route path="/login/paso-1" element={<LoginPaso1 />} />
                <Route
                    path="/login/notificacion"
                    element={<LoginNotificacion />}
                />

                {/* Dashboard */}
                <Route path="/user-dashboard" element={<DashboardHome />} />
                <Route
                    path="/user-dashboard/landing"
                    element={
                        <ProtectedRoute
                            value={merchant}
                            condition={true}
                            redirect="/user-dashboard"
                        >
                            <DashboardLanding />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/user-dashboard/user-sponsorship"
                    element={
                        <ProtectedRoute
                            value={merchant}
                            condition={true}
                            redirect="/user-dashboard"
                        >
                            <DashboardUserSponsorship />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/user-dashboard/user-connection"
                    element={
                        <ProtectedRoute
                            value={merchant}
                            condition={true}
                            redirect="/user-dashboard"
                        >
                            <DashboardUserConnection />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/user-dashboard/usuario"
                    element={
                        <ProtectedRoute
                            value={merchant}
                            condition={true}
                            redirect="/user-dashboard"
                        >
                            <DashboardUsuario />
                        </ProtectedRoute>
                    }
                />

                {/* Identity */}
                <Route path="/identity" element={<IdentityHome />} />
                <Route path="/identity/compra" element={<IdentityCompra />} />
                <Route path="/identity/paso-1" element={<IdentityPaso1 />} />
                <Route path="/identity/validar" element={<IdentityValidar />} />
                <Route path="/identity/usuario" element={<IdentityUsuario />} />
            </Routes>
        </Router>
    );
}
