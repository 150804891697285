import React from "react";
import classes from "./Card.module.scss";

export default function Card({ color, children }) {
    return (
        <div className={`${classes.card} ${classes[color]}`} data-testid="card">
            {children}
        </div>
    );
}
