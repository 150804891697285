import React from "react";

import {
    CloudBig,
    ImageIdentityModal,
    ImageIdentityModal2,
} from "../../../assets";
import { Button } from "../../../components";

import classes from "./ErrorScreen.module.scss";
import { validationConstants } from "./validationConstants";
import ErrorDaonPage from "../../Error/Daon";
import ErrorMicroblink from "../../Error/Microblink";
const CODES_TO_ERR = {
    3008929: "Fallo chequeo de liveness",
    3008915: "El rostro esta cortado",
    3008914: "El rostro esta cerca de algún borde",
    4000001: "No se encontro un documento",
    4000002:
        "No pudimos extraer toda la información que necesitamos del DNI. Limpia la camara y asegurate que el DNI este en buenas condiciones",
};

export const ErrorScreen = ({ errMsg, setStep }) => {
    const msg = CODES_TO_ERR[errMsg];
    console.log("Esto es error message en error screen", errMsg);
    const defaultErrorPage = () => (
        <div className={classes.errorContainer}>
            <img src={CloudBig} alt="cloud" className={classes.leftCloud} />

            <span className={classes.errorMessage}>
                <h4>¡Ups! Algo salió mal...</h4>
                <p>Por favor, inténtalo de nuevo</p>
                {errMsg && <p style={{ marginTop: "10px" }}>{errMsg}</p>}
            </span>

            <div className={classes.errorImgContainer}>
                <img
                    src={ImageIdentityModal2}
                    alt="Cruz"
                    className={classes.cruz}
                />
            </div>

            <Button
                type="primary"
                color="orange-strong"
                onClick={() => window.location.reload()}
                text="Reintentar"
            />
            <p
                className={classes.salir}
                onClick={() => setStep(validationConstants.DNI)}
            >
                Salir
            </p>
        </div>
    );


    switch (msg) {
        case CODES_TO_ERR[3008915]:
            return <ErrorDaonPage setStep={setStep} />;
        case CODES_TO_ERR[3008914]:
            return <ErrorDaonPage setStep={setStep} />;
        case CODES_TO_ERR[4000001]:
            return <ErrorMicroblink setStep={setStep} />;
        case CODES_TO_ERR[4000002]:
            return <ErrorMicroblink setStep={setStep} />;
        default:
            return defaultErrorPage();

    }
};
