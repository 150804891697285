import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Context";
import moment from "moment";
// Assets
import { IDLFace, QRCaducado } from "../../assets";
// Components
import QRCode from "react-qr-code";
import { Button, Card, Layout, Spinner } from "../../components";
import { LinearProgress } from "@mui/material";
// Servicios
import {
    clienteUsuarioGet,
    loginQREvent,
    loginQRGet,
    usuarioFotoGet,
    usuarioMinimosGet,
} from "../../services";

export default function QR() {
    const { merchant, userInfo, setUserInfo, setToastInfo } =
        useContext(GlobalContext);

    const [UUID, setUUID] = useState();
    const [loading, setLoading] = useState(true);
    const [expirado, setExpirado] = useState(false);
    const [usuarioImagen, setUsuarioImagen] = useState();
    const [coord, setCoord] = useState();
    const [progress, setProgress] = useState(0);
    const intentos = useRef(0);

    const handleError = useCallback(
        (text) => {
            setToastInfo({ open: true, text });
            setLoading(false);
        },
        [setToastInfo]
    );

    const getQR = useCallback(async () => {
        if (!coord) return;
        setExpirado(false);
        setLoading(true);
        const data = await loginQRGet(
            coord.lat.toFixed(6),
            coord.lng.toFixed(6)
        );
        if (!data) return handleError("Ha ocurrido un error inesperado");
        setProgress(0);
        setUUID(data.codigoQR);
        setLoading(false);
    }, [coord, handleError]);

    const getQREvent = useCallback(async () => {
        if (!UUID) return;

        const response = await loginQREvent(UUID);

        if (response.status.code === 1 && merchant) {
            const user = await usuarioMinimosGet(response.result[0]);
            const usuario = (
                await clienteUsuarioGet(
                    merchant.merchantId,
                    null,
                    user.usuarioId
                )
            ).filter((u) => u.relacionStatus === "Activo")[0];

            const imagenUsuario = await usuarioFotoGet(user.usuarioId);
            setUsuarioImagen(imagenUsuario);
            return !usuario
                ? setUserInfo(user)
                : setUserInfo({ ...user, ...usuario });
        }

        intentos.current++;
        setUUID(null);
        intentos.current < 2 ? getQR() : setExpirado(true);
    }, [getQR, setUserInfo, UUID, merchant]);

    useEffect(() => {
        setUserInfo(null);
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoord({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                });
            },
            (error) => console.log(error)
        );

        const timer = setInterval(() => {
            setProgress((oldProgress) =>
                oldProgress + 60 / 580 >= 100
                    ? 100
                    : oldProgress + ((60 / 580) * 100) / 60
            );
        }, (60 / 580) * 1000);

        return () => {
            clearInterval(timer);
        };
    }, [setUserInfo]);

    useEffect(() => {
        getQR();
    }, [getQR]);

    useEffect(() => {
        getQREvent();
    }, [getQREvent]);

    if (loading)
        return (
            <Layout>
                <Card color="blue">
                    <Spinner />
                </Card>
            </Layout>
        );
    if (userInfo)
        return (
            <Layout>
                <Card color="blue">

                    <img
                        src={
                            usuarioImagen?.imagePathPERFIL ||
                            usuarioImagen?.imagePathSELFIE ||
                            IDLFace
                        }
                        alt="Usuario"
                        className="imagen-perfil"
                    />


                    <h2>¡Hola, {userInfo.usuarioNombres}!</h2>
                    {userInfo.rolNombre && userInfo.servicioNombre && (
                        <p>
                            Sos un usuario, conectado a{" "}
                            <b>{merchant?.merchantRZ}</b> como{" "}
                            <b>{userInfo.rolNombre}</b> con el servicio{" "}
                            <b>{userInfo.servicioNombre}.</b>
                        </p>
                    )}
                    <p>
                        Fecha de nacimiento:{" "}
                        <b>
                            {moment(userInfo.usuarioFechaDeNacimiento).format(
                                "DD/MM/YYYY"
                            )}
                        </b>
                    </p>
                    <p>
                        Sexo:{" "}
                        <b>
                            {userInfo.usuarioSexo === "M"
                                ? "Masculino"
                                : userInfo.usuarioSexo === "F"
                                    ? "Femenino"
                                    : "-"}
                        </b>
                    </p>
                    <p>
                        Email: <b>{userInfo.usuarioMail || "-"}</b>
                    </p>
                    <p>
                        Telefono:{" "}
                        <b>{userInfo.usuarioTelefonoCelular || "-"}</b>
                    </p>
                    <Button type="primary" color="blue">
                        <Link to="/cierre">Finalizar</Link>
                    </Button>
                </Card>
            </Layout>
        );

    return (
        <Layout>
            <Card color="blue">
                {expirado ? (
                    <>
                        <h2>Ups!</h2>
                        <p>
                            El código caducó, generá un <span>nuevo QR</span>{" "}
                            para poder ingresar.
                        </p>
                        <img src={QRCaducado} alt="QR caduco" />
                        <Button type="primary" color="blue" onClick={getQR}>
                            Generar QR
                        </Button>
                    </>
                ) : (
                    <>
                        <h2>¡Hola!</h2>
                        <p>
                            Para poder ingresar, escanea el QR desde la App de
                            MIA.
                        </p>
                        {UUID && (
                            <>
                                <QRCode value={UUID} color={"#4565AD"} />
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    style={{
                                        width: "100%",
                                        marginTop: "16px",
                                        color: "#4565AD",
                                    }}
                                />
                            </>
                        )}
                    </>
                )}
            </Card>
        </Layout>
    );
}
