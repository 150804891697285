import axiosPost from "./axiosPost";

export const notificatorSend = async (email) => {
    const body = {
        service: "NotificatorSend",
        params: {
            notificatorEmail: email,
            notificatorTemplateEmail: "mia",
            notificatorEmailSujeto: "Descarga la app de MIA",
            notificatorPlaceholderValores: [],
        },
    };

    const result = await axiosPost(body);
    return result;
};
