import React from "react";
import classes from "./IDL.module.scss";
import { IDLFace, IDLHuella, IDLNative, IDLPalm } from "../../../assets";

export default function IDL({ datos }) {
    return (
        <div className={classes.container}>
            <div className={classes.scores}>
                <h4>Scores</h4>
                <div>
                    <p>MATCH IDX</p>
                    <p>{datos.usuarioDNIMatchScore}</p>
                    <span></span>
                </div>
                <div>
                    <p>MATCH RENAPER</p>
                    <p>{datos.usuarioDNIMatchRENAPER}</p>
                    <span></span>
                </div>
                <div>
                    <p>SCORE MIA</p>
                    <p>0,59</p>
                    <span></span>
                </div>
                <div>
                    <p>NSE</p>
                    <p>0,59</p>
                    <span className={classes.yellow}></span>
                </div>
                <div>
                    <p>AML</p>
                    <p>0,59</p>
                    <span></span>
                </div>
            </div>
            <div className={classes.autenticadores}>
                <h4>Autenticadores habilitados</h4>
                <div className={classes.autenticador}>
                    <img src={IDLFace} alt="Face" />
                    <div className={classes.datos}>
                        <h4>FIDO Face</h4>
                        <div>
                            <p>
                                Failed attemps<span>15</span>
                            </p>
                            <p>
                                Unlock count<span>10</span>
                            </p>
                        </div>
                        <div>
                            <p>
                                Ingreso a la app<span>Primario</span>
                            </p>
                            <p>
                                Gate<span>Primario</span>
                            </p>
                            <p>
                                Login by MIA<span>Secundario</span>
                            </p>
                        </div>
                    </div>
                    <div className={classes.status}>Activo</div>
                </div>
                <div className={classes.autenticador}>
                    <img src={IDLHuella} alt="Huella" />
                    <div className={classes.datos}>
                        <h4>Finger</h4>
                        <div>
                            <p>
                                Failed attemps<span>15</span>
                            </p>
                            <p>
                                Unlock count<span>10</span>
                            </p>
                        </div>
                        <div>
                            <p>
                                Ingreso a la app<span>Primario</span>
                            </p>
                            <p>
                                Gate<span>Primario</span>
                            </p>
                            <p>
                                Login by MIA<span>Secundario</span>
                            </p>
                        </div>
                    </div>
                    <div className={classes.status}>Activo</div>
                </div>
                <div className={classes.autenticador}>
                    <img src={IDLNative} alt="Native" />
                    <div className={classes.datos}>
                        <h4>Native face</h4>
                        <div>
                            <p>
                                Failed attemps<span>15</span>
                            </p>
                            <p>
                                Unlock count<span>10</span>
                            </p>
                        </div>
                        <div>
                            <p>
                                Ingreso a la app<span>Primario</span>
                            </p>
                            <p>
                                Gate<span>Primario</span>
                            </p>
                            <p>
                                Login by MIA<span>Secundario</span>
                            </p>
                        </div>
                    </div>
                    <div className={`${classes.status} ${classes.bloqueado}`}>
                        Bloqueado
                    </div>
                </div>
                <div className={classes.autenticador}>
                    <img src={IDLPalm} alt="Palm" />
                    <div className={classes.datos}>
                        <h4>Palm</h4>
                        <div>
                            <p>
                                Failed attemps<span>15</span>
                            </p>
                            <p>
                                Unlock count<span>10</span>
                            </p>
                        </div>
                        <div>
                            <p>
                                Ingreso a la app<span>Primario</span>
                            </p>
                            <p>
                                Gate<span>Primario</span>
                            </p>
                            <p>
                                Login by MIA<span>Secundario</span>
                            </p>
                        </div>
                    </div>
                    <div className={`${classes.status} ${classes.noenrolado}`}>
                        No enrolado
                    </div>
                </div>
            </div>
        </div>
    );
}
