import React from "react";
import classes from "./Grid.module.scss";
import { DataGrid } from "@mui/x-data-grid";
import { generateColumns, validateRowSelected } from "../../../utils";

export default function Grid({ selected, rows, titles, setSelected }) {
    const columns = generateColumns(titles);

    return (
        <div className={classes.container}>
            <DataGrid
                columns={columns}
                rows={rows}
                autoPageSize
                hideFooterSelectedRowCount
                pagination
                showCellRightBorder={false}
                rowHeight={36}
                disableColumnMenu
                onRowClick={(data, event) =>
                    validateRowSelected(data, event, selected, setSelected)
                }
            />
        </div>
    );
}
