import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ImageIdentityModal, ImageIdentityModal2 } from '../../../assets';
import classes from "./index.module.scss";
import { Button } from '../../../components';
import { validationConstants } from '../../Identity/ValidationSteps/validationConstants';
function ErrorMicroblink({ setStep }) {
    return (
        <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} gap={5}>
            <Grid item>
                <Typography variant={"h4"}>
                    No pudimos reconocer D.N.I
                </Typography>
                <Typography variant='p'>
                    Por favor, inténtalo de nuevo
                </Typography>
            </Grid>
            <Grid item>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} position={"relative"}>
                    <img src={ImageIdentityModal} />
                    <img src={ImageIdentityModal2} className={classes.cruz} />
                </Box>

            </Grid>
            <Button
                type="primary"
                color="orange-strong"
                onClick={() => window.location.reload()}
                text="Reintentar"
            />
            <p
                className={classes.salir}
                onClick={() => setStep(validationConstants.DNI)}
            >
                Salir
            </p>
        </Grid>
    );
}

export default ErrorMicroblink;
