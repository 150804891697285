import React, { useContext, useEffect } from 'react';
import { Spinner } from '../../../components';
import { validationConstants } from './validationConstants';
import { GlobalContext } from '../../../Context';
import ProgressOval from '../../../components/ProgressOval';
import "./SelfieValidation.module.scss";
import classes from "./SelfieValidation.module.scss";
import { Box, Grid } from '@mui/material';
import { CircularProgressbarWithChildren, CircularProgressbar } from 'react-circular-progressbar';
export const SelfieValidationStep = ({ setStep }) => {
    const selfieFeed = React.useRef(null);
    const [loading, setLoading] = React.useState(true);
    const [feedbackMsg, setFeedbackMsg] = React.useState('Pon tu cara dentro del ovalo');
    const [accepted, setAccepted] = React.useState(false);
    const [percent, setPercent] = React.useState(0);

    const { setSelfie } = useContext(GlobalContext);

    const initDaon = () => {
        if (typeof window !== "undefined") {
            const { Daon } = window;
            const configuration = {
                width: 1280,
                height: 720,
                thresholds: {
                    ThresholdInterEyesDistance: 150,
                },
            };
            const fc = new Daon.FaceCapture(configuration);
            //loads wasm modules
            fc.loadDFQModule({
                urlFaceDetectorWasm: window.location.origin + "/DaonFaceQuality.wasm",
                onFaceModuleLoaded: ({ isLoaded, error }) => {
                    if (isLoaded) {
                        setLoading(false);
                        fc.isGyroscopeActive().then(() => {
                            //starts the camera
                            fc.startCamera(selfieFeed.current).then(r => {
                                selfieFeed.current.onloadedmetadata = () => {
                                    startFaceDetector(fc);
                                };
                            });
                        }).catch((err) => {
                            console.log(err);
                            setStep(validationConstants.ERROR);
                            //user should be given an option to enable motion and sensor data so he can retry the camera init
                        });
                    }
                    if (error) {
                        setStep(validationConstants.ERROR);
                        console.log(error);
                    }

                },
            });
        }
    };
    let percent1 = percent;
    const startProgressBar = () => {
        document.querySelectorAll('.CircularProgressbar').forEach((e) => {
            percent1++;
            setPercent(percent1);
        });
    };

    function startFaceDetector(fc) {
        fc.startFaceDetector({
            onFaceDetectorInitialized: function () {
                fc.findFace();
            },
            onFaceDetectorError: function (err) {
                //logic when error occurs
                setStep(validationConstants.ERROR);
                console.log(err);
            },
            onFaceDetectorFeedback: function (detectorFeedbackObject) {
                //Logic on feedback
                if (detectorFeedbackObject.result === "PASS") {
                    //user sucessfully captured selfie, selfie is of sufficiant quality

                    console.log(detectorFeedbackObject);
                    setSelfie(detectorFeedbackObject);
                    //finish progress oval
                    setPercent(10);
                    fc.destroy();
                    setTimeout(() => {
                        setStep(validationConstants.VALIDATING);
                    }, 1000);

                } else {
                    // selfie image is of insuficient quality (it is mandatory to keep invoking findFace in the loop until selfie image of a good quality is detected)
                    setTimeout(() => {
                        fc.findFace();
                    }, 500);

                    switch (detectorFeedbackObject.feedback.message) {
                        case 'Face too small. Please move closer to camera':
                            setFeedbackMsg('Por favor, acercate más a la camara.');
                            setPercent(0);
                            setAccepted(false);
                            break;
                        case 'Please center your face in the oval':
                            setPercent(0);
                            setFeedbackMsg('Por favor, centra tu cara en el ovalo.');
                            setAccepted(false);
                            break;
                        case 'Hold on for a few seconds':
                            setFeedbackMsg('Quedate quieto por unos segundos.');
                            setAccepted(true);
                            startProgressBar();
                            break;
                        default:
                            setPercent(0);
                            setFeedbackMsg('No se pudo encontrar un rostro.');
                            setAccepted(false);
                            break;
                    }

                }
            },
        }
            // , {
            //     serverPublicKey,
            //     idxUserId
            // }
        );

    }

    useEffect(() => {
        initDaon();
    }, []);

    return (
        <Grid container display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Grid item>
                <h2 className={"title"}>Momento selfie</h2>
                <p className={classes["subtitle"]}>{loading ? 'Cargando, esperá unos segundos' : feedbackMsg}</p>
            </Grid>
            <Box className={classes['containerCircularProgress']} >
                <CircularProgressbarWithChildren value={percent * 10} styles={{
                    // Customize the root svg element
                    root: {
                    },
                    // Customize the path, i.e. the "completed progress"
                    path: {
                        // Path color
                        stroke: `#00a135`,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Customize transition animation
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                        // Rotate the path
                        transform: 'rotate(0.0turn)',
                        transformOrigin: 'center center',
                        strokeWidth: 3
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                        // Trail color
                        stroke: `#171b37`,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Rotate the trail
                        transform: 'rotate(0.0turn)',
                        transformOrigin: 'center center',
                        strokeWidth: 3

                    },
                }} >

                    <video ref={selfieFeed} id="selfie-feed" playsInline></video>
                </CircularProgressbarWithChildren>
            </Box>
        </Grid>















    );
};
