import axiosPost from "./axiosPost";

export const connectionUpd = async (uuid, estado, userId) => {
    const body = {
        service: "MIADemoConnectionWebUpd",
        params: {
            conexionUUID: uuid,
            conexionEstado: estado,
            usuarioId: userId,
        },
    };

    const result = await axiosPost(body);
    console.log("connectionUpd", result);
    return result;
};
