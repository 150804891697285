import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconDNI } from "../../assets";
import { Button, Card, Input, Layout } from "../../components";
import { GlobalContext } from "../../Context";
import { formatDNI } from "../../utils";

export default function Paso1() {
    const navigate = useNavigate();
    const { setUserInfo, setToastInfo } = useContext(GlobalContext);

    const [DNI, setDNI] = useState("");

    useEffect(() => {
        setUserInfo(null);
    }, [setUserInfo]);

    const handleError = (text) => {
        setToastInfo({ text, open: true });
    };
    const handleSubmit = () => {
        if (DNI === "") return handleError("Por favor ingrese un DNI");
        const formattedDNI = DNI.replace(/\./g, "");
        if (formattedDNI.length < 7 || formattedDNI.length > 8)
            return handleError("Por favor ingrese un DNI valido");

        navigate(`/login/notificacion?dni=${formattedDNI}`);
    };

    const handleChange = (value) => formatDNI(value, setDNI);

    return (
        <Layout>
            <Card color="blue">
                <div className="paso">1</div>
                <h2>Login by MIA</h2>
                <p className="texto login paso1">
                    Elegí una persona de tu equipo para realizar la prueba y
                    seguí los pasos que te indicamos a continuación.
                </p>
                <Input
                    name="dni"
                    label="DNI"
                    type="text"
                    placeholder="22334455"
                    value={DNI}
                    setValue={handleChange}
                    image={IconDNI}
                />
                <Button
                    type="primary"
                    color="blue"
                    text="Continuar"
                    onClick={handleSubmit}
                />
            </Card>
        </Layout>
    );
}
