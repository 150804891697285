import React from "react";
import { DashboardHeader } from "..";
import { StyledEngineProvider } from "@mui/material/styles";
import classes from "./Layout.module.scss";

export default function DashboardLayout({ title, children }) {
    return (
        <StyledEngineProvider injectFirst>
            <div className={classes.container}>
                <DashboardHeader title={title} />
                {children}
            </div>
        </StyledEngineProvider>
    );
}
