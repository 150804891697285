import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AnimationInfoWow } from "../../assets/animations";
import classes from "./Cierre.module.scss";

export default function Cierre() {
    const navigate = useNavigate();
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.play();
            setTimeout(() => navigate("/demo"), 1000 * 18);
        }
    }, [videoRef, navigate]);

    return (
        <video
            src={AnimationInfoWow}
            autoPlay
            playsInline
            ref={videoRef}
            className={classes.video}
        />
    );
}
