import React from "react";
import HeaderIdentity from "../HeaderIdentity";
import { CloudBig } from "../../assets";

import classes from "./IdentityLayout.module.scss";

export default function Layout({ children }) {
    return (
        <>
            <HeaderIdentity />
            <main className={classes.main}>
                <img src={CloudBig} alt="cloud" className={classes.leftCloud} />
                <div className={classes.cardContainer}>{children}</div>
            </main>
        </>
    );
}
