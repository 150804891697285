import React from "react";
import { Link } from "react-router-dom";
import { Plane, QR as QRImage } from "../../assets";
import { Button, Card, Layout } from "../../components";

export default function QR() {
    return (
        <Layout>
            <Card color="blue">
                <img src={Plane} alt="Enviado" />
                <h2>¡Listo!</h2>
                <p>
                    Para que el usuario pueda iniciar el proceso, deberá
                    escanear este QR con la cámara de su celular y descargar la
                    App de MIA
                </p>
                <img src={QRImage} alt="QR" className="qr" />
                <p className="texto sponsorship qr">
                    ¡Le hemos enviado esta misma información a su casilla de
                    correo, para que pueda descargarse la App y empezar a vivir
                    una experiencia de una identidad más segura!
                </p>
                <Button type="primary" color="blue">
                    <Link to="/cierre">Finalizar</Link>
                </Button>
            </Card>
        </Layout>
    );
}
