import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../Context";
// Assets
import { IconMail, IconRol } from "../../assets";
// Components
import { Button, Card, Input, Layout, Modal, Spinner } from "../../components";
import { v4 as uuidv4 } from "uuid";
// Services
import {
    connectionIns,
    servicioGet,
    rolGet,
    connectionCheck,
    connectionUpd,
} from "../../services";
import {
    AnimationConexionEnviada,
    AnimationMIAID,
} from "../../assets/animations";
import Lottie from "react-lottie-player";

export default function Usuario() {
    const navigate = useNavigate();
    const { merchant, userInfo, setToastInfo } = useContext(GlobalContext);

    const [open, setOpen] = useState(false);
    const [esperando, setEsperando] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rolesLoading, setRolesLoading] = useState(false);
    const [servicios, setServicios] = useState([]);
    const [servicio, setServicio] = useState("");
    const [roles, setRoles] = useState([]);
    const [rol, setRol] = useState("");
    const [uuid, setuuid] = useState(uuidv4());

    const handleError = useCallback(
        (text) => {
            setToastInfo({ open: true, text });
            setLoading(false);
            setEsperando(false);
        },
        [setToastInfo]
    );

    useEffect(() => {
        // Recuperar servicios
        (async () => {
            if (!merchant || !userInfo) return;
            setLoading(true);
            const { status, result } = await servicioGet(
                merchant.merchantId,
                userInfo.usuarioId
            );
            if (status.code === 0) return handleError(status.errmsg);
            const servicios = result.map((s) => ({
                value: s.servicioId.toString(),
                name: s.servicioNombre,
            }));
            setServicios(servicios);
            if (servicios[0]) setServicio(servicios[0].value);
            setLoading(false);
        })();
    }, [handleError, merchant, userInfo]);

    useEffect(() => {
        // Recuperar roles
        (async () => {
            if (!servicio) return;
            setRolesLoading(true);
            setRoles([]);
            setRol("");
            const { status, result } = await rolGet(parseInt(servicio));
            if (status.code === 0) return handleError(status.errmsg);
            const roles = result.map((rol) => ({
                value: rol.rolId.toString(),
                name: rol.rolNombre,
                servicio: rol.servicioId,
            }));
            setRoles(roles);
            if (roles[0]) setRol(roles[0].value);
            setRolesLoading(false);
        })();
    }, [servicio, handleError]);

    const handleConnection = async () => {
        if (!uuid || !userInfo || !merchant) return;
        if (!servicio) return handleError("Por favor seleccione un servicio");
        if (!rol) return handleError("Por favor seleccione un rol");

        const response = await connectionIns(
            uuid,
            merchant.merchantId,
            userInfo.usuarioId,
            userInfo.usuarioNroDeDocumento,
            "C",
            parseInt(rol),
            parseInt(servicio)
        );
        if (response.code === 0) return handleError(response.errmsg);
        setEsperando(true);
    };

    useEffect(() => {
        if (!esperando) return;
        const checkConnection = setInterval(async () => {
            const response = await connectionCheck(uuid);

            const aprobado = response.conexionEstado === "A";
            const debeChequear = response.conexionEstado === "P";
            const rechazado = response.conexionEstado === "R";

            if (debeChequear) return;
            clearInterval(checkConnection);

            if (aprobado) return navigate("/user-connection/aceptado");
            if (rechazado) return navigate("/user-connection/rechazado");
        }, 3000);

        return () => {
            clearInterval(checkConnection);
        };
    }, [esperando, navigate, uuid, userInfo]);

    const cancelConnection = () => {
        connectionUpd(uuid, "X", userInfo?.usuarioId);
        setuuid(uuidv4());
        setOpen(false);
        setEsperando(false);
    };

    if (!userInfo) return <></>;

    if (loading)
        return (
            <Layout>
                <Card color="blue">
                    <Spinner />
                </Card>
            </Layout>
        );

    if (esperando)
        return (
            <Layout>
                <Modal
                    open={open}
                    setOpen={setOpen}
                    text={`¿Estás seguro que deseas cancelar la invitación a ${userInfo.usuarioNombres} ${userInfo.usuarioApellidos}?`}
                    handleClick={cancelConnection}
                    color="blue"
                />

                <Card color="blue">
                    <Lottie
                        animationData={AnimationConexionEnviada}
                        play
                        loop={true}
                    />
                    <p className="texto connection usuario">
                        Aguardá en esta pantalla que tu invitado acepte la
                        conexion, o continua navegando por nuestra Demo.
                        <br />
                        Si optás por continuar siempre podrás consultar el
                        estado de esta conexión desde el Dashboard.
                    </p>
                    <div className="buttons-container">
                        <Button type="primary" color="blue">
                            <Link to="/demo">Continuar navegando</Link>
                        </Button>
                        <Button
                            type="primary"
                            color="blue"
                            text="Cancelar conexión"
                            onClick={() => setOpen(true)}
                        />
                    </div>
                </Card>
            </Layout>
        );

    return (
        <Layout>
            <Modal
                open={open}
                setOpen={setOpen}
                text={`¿Estás seguro que deseas cancelar la invitación a ${userInfo?.usuarioNombres} ${userInfo?.usuarioApellidos}?`}
                handleClick={cancelConnection}
            />

            <Card color="blue">
                <div className="paso">2</div>
                <h2>¡Ya es usuario MIA!</h2>
                {servicios.length > 0 && (
                    <p>
                        Vamos a conectar a {userInfo.usuarioNombres}{" "}
                        {userInfo.usuarioApellidos} con{" "}
                        {merchant?.merchantNombre}, elige el servicio y el rol
                        con que quieres conectarlo
                    </p>
                )}
                <Lottie animationData={AnimationMIAID} play loop={true} />
                {servicios.length === 0 ? (
                    <>
                        <p>No hay servicios disponibles para este usuario</p>
                        <Button type="primary" color="blue">
                            <Link to="/demo">Regresar</Link>
                        </Button>
                    </>
                ) : (
                    <>
                        <Input
                            name="servicio"
                            label="Servicio"
                            type="select"
                            value={servicio}
                            setValue={setServicio}
                            image={IconMail}
                            options={servicios}
                        />
                        {roles.length === 0 && !rolesLoading ? (
                            <p>No hay roles disponibles para este servicio</p>
                        ) : (
                            <Input
                                name="rol"
                                label="Rol"
                                type="select"
                                value={rol}
                                setValue={setRol}
                                image={IconRol}
                                options={roles}
                            />
                        )}
                        <Button
                            type="primary"
                            color="blue"
                            text="Continuar"
                            onClick={handleConnection}
                        />
                    </>
                )}
            </Card>
        </Layout>
    );
}
