import React from "react";
import styles from "./Carousel.module.scss";
import { NavigateNext, NavigateBefore } from "@mui/icons-material";
import CardImage from "./CardImage/CardImage";

//Vars
const SAMSUNG_IMAGES = [
    "https://samsungar.vtexassets.com/arquivos/ids/184374-150-auto?width=150&height=auto&aspect=true",
    "https://samsungar.vtexassets.com/arquivos/ids/183269-150-auto?width=150&height=auto&aspect=true",
    "https://samsungar.vtexassets.com/arquivos/ids/183270-150-auto?width=150&height=auto&aspect=true",
    "https://samsungar.vtexassets.com/arquivos/ids/183271-150-auto?width=150&height=auto&aspect=true",
    "https://samsungar.vtexassets.com/arquivos/ids/183272-150-auto?width=150&height=auto&aspect=true",
];

export default function Carousel({ children }) {
    return (
        <div className={styles["carousel-container"]}>
            <div className={styles["carousel-wrapper"]}>
                <button className={styles["left-arrow"]}>
                    <NavigateBefore fontSize="small" />
                </button>

                <div className={styles["carousel-content-wrapper"]}>
                    <div className={styles["carousel-content"]}>{children}</div>
                </div>

                <button className={styles["right-arrow"]}>
                    <NavigateNext fontSize="small" />
                </button>
            </div>

            {/*Image label */}
            <div className={styles["images-group"]}>
                {SAMSUNG_IMAGES.map((image, index) => (
                    <CardImage key={index} image={image} />
                ))}
            </div>
        </div>
    );
}
