import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [merchant, setMerchant] = useState({
        merchantId: 1,
        merchantNombre: "Moov",
        merchantRZ: "Moov",
    });
    const [userInfo, setUserInfo] = useState(null);
    const [toastInfo, setToastInfo] = useState({ open: false, text: "" });
    const [qualityAssessment, setQualityAssessment] = useState({});
    const [dniInfo, setDniInfo] = useState(null);
    const [selfie, setSelfie] = useState(null);

    const providerValue = {
        merchant,
        setMerchant,
        userInfo,
        setUserInfo,
        toastInfo,
        setToastInfo,
        dniInfo,
        setDniInfo,
        selfie,
        setSelfie,
        qualityAssessment,
        setQualityAssessment,
    };

    return (
        <GlobalContext.Provider value={providerValue}>
            {children}
        </GlobalContext.Provider>
    );
};
