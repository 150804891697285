import React from "react";
import classes from "./index.module.scss";
import { useNavigate } from "react-router-dom";
function LegajoTucuman() {
    const navigate = useNavigate();
    return (
        <div
            className={classes.bg}
            onClick={() => navigate("/identity/validar")}
        ></div>
    );
}

export default LegajoTucuman;
