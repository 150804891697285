import React, { useContext, useEffect, useState, useRef } from "react";
import { Check } from "../../../assets";
import { IdentityValidando, LoaderProcess } from "../../../assets/animations";
import { validationConstants } from "./validationConstants";
import Lottie from "react-lottie-player";
import { GlobalContext } from "../../../Context";
import axios from "axios";
import { ValidationBadges } from "./ValidationBadges";

import classes from "./ValidationProcessScreen.module.scss";

export const ValidationProcessScreen = ({ setStep, setErrMsg }) => {
    const [seconds, setSeconds] = useState(0);
    const [passResponse, setPassResponse] = useState({
        antispoofing: false,
        liveness: false,
        fotografia: false
    });
    const { selfie, dniInfo, setUserInfo, setQualityAssessment, userInfo } =
        useContext(GlobalContext);
    const videoRef = useRef();
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.play();
            console.log("Video ", videoRef);
        }
    }, [videoRef]);
    // Procesar en backend
    useEffect(() => {
        async function process() {
            const selfieBase64 = selfie.faceImage;
            const selfieScore = selfie.score;

            var canvasFrente = document.createElement("canvas");
            var canvasDorso = document.createElement("canvas");
            var canvasFace = document.createElement("canvas");

            const ctxFrente = canvasFrente.getContext("2d");
            const ctxDorso = canvasDorso.getContext("2d");
            const ctxFace = canvasFace.getContext("2d");

            const result = dniInfo;

            canvasFrente.width = result.fullDocumentFrontImage.rawImage.width;
            canvasDorso.width = result.fullDocumentBackImage.rawImage.width;
            canvasFace.width = result.faceImage.rawImage.width;

            canvasFrente.height = result.fullDocumentFrontImage.rawImage.height;
            canvasDorso.height = result.fullDocumentBackImage.rawImage.height;
            canvasFace.height = result.faceImage.rawImage.height;

            ctxFrente.putImageData(
                result.fullDocumentFrontImage.rawImage,
                0,
                0
            );
            ctxDorso.putImageData(result.fullDocumentBackImage.rawImage, 0, 0);
            ctxFace.putImageData(result.faceImage.rawImage, 0, 0);

            const frenteImage = canvasFrente.toDataURL("image/png");
            const dorsoImage = canvasDorso.toDataURL("image/png");
            const dniFotoBase64 = canvasFace.toDataURL("image/png");

            try {
                const URL = "https://rp.hooli.la/RPGoServices";

                const barcodeData =
                    result.barcode.barcodeData.stringData.split("@");
                console.log(barcodeData);

                // Crear usuario
                const createUserResponse = await axios.post(URL, {
                    service: "IDXUsuarioCrearDo",
                    params: {
                        userId:
                            barcodeData[4] ||
                            result.documentNumber ||
                            result.personalIdNumber,
                    },
                });
                console.log("userresponse", createUserResponse.data);

                const userId = createUserResponse.data.result[0].id;

                // Enrolar cara
                const selfieB64Data = selfieBase64.split(",")[1];

                console.log(
                    "body vieja",
                    {
                        service: "IDXUsuarioMuestraIns",
                        params: {
                            userId: userId,
                            format: "PNG",
                            sample: selfieB64Data,
                        },
                    },
                    "URL:" + URL
                );

                const enrollFaceResponse = await axios.post(URL, {
                    service: "IDXUsuarioMuestraIns",
                    params: {
                        userId: userId,
                        format: "PNG",
                        sample: selfieB64Data,
                    },
                });
                console.log("enrollFaceResponse", enrollFaceResponse.data);

                if (enrollFaceResponse.data.status.code !== 1) {
                    setErrMsg(enrollFaceResponse.data.errmsg);
                    setStep(validationConstants.ERROR);
                }
                setQualityAssessment({
                    ...enrollFaceResponse.data.result[0].qualityAssessment,
                });
                // Crear idcheck
                const idCheckCreationResponse = await axios.post(URL, {
                    service: "IDXIDCheckIns",
                    params: {
                        userId,
                    },
                });
                console.log("createid", idCheckCreationResponse.data);

                const idCheckId = idCheckCreationResponse.data.result[0].id;

                // Passive liveness image
                const passiveLivenesResponse = await axios.post(URL, {
                    service: "IDXPLImageIns",
                    params: {
                        userId: userId,
                        idCheck: idCheckId,
                        format: "PNG",
                        sample: selfieB64Data,
                    },
                });
                console.log("passiveliveness", passiveLivenesResponse.data);
                if (passiveLivenesResponse.data.result[0].processed) {
                    setPassResponse({ ...passResponse, liveness: true });
                }
                // Document image
                const dniFotoB64Data = dniFotoBase64.split(",")[1];
                const documentResponse = await axios.post(URL, {
                    service: "IDXDocImageIns",
                    params: {
                        userId: userId,
                        idCheck: idCheckId,
                        format: "PNG",
                        sample: dniFotoB64Data,
                    },
                });
                console.log("docresponse", documentResponse.data);

                // Crear idcheck
                const evaluateResponse = await axios.post(URL, {
                    service: "IDXEvaluarDo",
                    params: {
                        userId: userId,
                        idCheck: idCheckId,
                    },
                });
                console.log("evaluate", evaluateResponse.data);

                const idface = evaluateResponse.data.result[0].evaluations.find(
                    (ev) => ev.source === "IDFACE"
                );
                const trusted =
                    evaluateResponse.data.result[0].evaluations.find(
                        (ev) => ev.source === "TRUSTED_SOURCE_PROVIDED"
                    );

                const livenessScore = idface?.score || 0;
                const antispoofingScore = trusted?.score || 100;
                const antispoofingFMR = trusted?.fmr || 100;

                setPassResponse({ ...passResponse, antispoofing: true, fotografia: true, liveness: true });
                setUserInfo({
                    usuarioApellidos: barcodeData[1],
                    usuarioId: userId,
                    usuarioNombres: barcodeData[2],
                    usuarioNroDeDocumento: barcodeData[4],
                    // fecha emision
                    usuarioDocumentoFechaEmision: barcodeData[7],
                    // fecha nacimiento
                    usuarioFechaDeNacimiento: barcodeData[6],
                    usuarioSexo: barcodeData[3],
                    usuarioDocumentoNumeroTramite: barcodeData[0],
                    // usuarioMail: string,
                    scoreSelfie: selfieScore,
                    scoreLiveness: livenessScore,
                    scoreAntispoofing: antispoofingScore,
                    antispoofingFMR,
                    imagePathDNIFrente: frenteImage,
                    imagePathDNIDorso: dorsoImage,
                    imagePathSELFIE: selfieBase64,
                });
            } catch (err) {
                console.error(err);
                setStep(validationConstants.ERROR);
            }
        }

        process();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((seconds) => seconds + 1.5);
        }, 1500);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (passResponse.antispoofing && passResponse.fotografia && passResponse.liveness) {
            setTimeout(() => {
                setStep(validationConstants.VALIDATED);
            }, 2000);
        }
    }, [passResponse]);

    return (
        <div className={classes.validationContainer}>
            <Lottie
                animationData={IdentityValidando}
                play
                className="animation"
                loop
            />

            <h1>Estamos validando tu identidad</h1>

            <ValidationBadges success={passResponse} />
        </div>
    );
};
