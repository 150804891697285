import React from "react";
import classes from "./Button.module.scss";

export default function Button({
    type,
    color,
    text,
    children,
    onClick,
    customClass,
}) {
    const className = `${classes.button} ${classes[type]} ${classes[color]} ${classes[customClass]}`;

    return (
        <button className={className} onClick={onClick}>
            {text || children}
        </button>
    );
}
