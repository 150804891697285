import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { MiaCard } from "../../assets";
import { Button, Card, Layout } from "../../components";
import { GlobalContext } from "../../Context";

export default function Usuario() {
    const { merchant, userInfo } = useContext(GlobalContext);

    if (!userInfo) return <></>;

    return (
        <Layout text="¡Hola! Coca Cola">
            <Card color="blue">
                <div className="paso">2</div>
                <h2>¡Ya es usuario MIA!</h2>
                <p>
                    {userInfo.usuarioNombres} {userInfo.usuarioApellidos} ya se
                    encuentra conectado a {merchant?.merchantNombre} como{" "}
                    <b>{userInfo.rolNombre.toUpperCase()}</b>
                </p>
                <img src={MiaCard} alt="MIA card" />
                <Button type="primary" color="blue">
                    <Link to="/demo">Volver al inicio</Link>
                </Button>
            </Card>
        </Layout>
    );
}
