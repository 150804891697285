import React from "react";
import classes from "./index.module.scss";
import { useNavigate } from "react-router-dom";
function GobiernoTucuman() {
    const navigate = useNavigate();
    return (
        <div
            className={classes.bg}
            onClick={() => navigate("/gob-tucuman-empleado")}
        ></div>
    );
}

export default GobiernoTucuman;
