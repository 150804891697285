import React from "react";
import { IdentitySelfie } from "../../../assets/animations";
import { Button } from "../../../components";
import { validationConstants } from "./validationConstants";
import Lottie from "react-lottie-player";

import styles from "./SelfieInitialStep.module.scss";

export const SelfieInitialStep = ({ setStep }) => {
    function gyroPerm() {
        if (
            typeof DeviceMotionEvent !== "undefined" &&
            typeof DeviceMotionEvent.requestPermission === "function"
        ) {
            DeviceMotionEvent.requestPermission()
                .then((r) => {
                    if (r == "granted") {
                        setStep(validationConstants.TAKE_SELFIE);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setStep(validationConstants.ERROR);
                });
        } else setStep(validationConstants.TAKE_SELFIE);
    }

    return (
        <div className={styles.container}>
            <span className={styles.titleContainer}>
                <h1>Vamos a validar tu identidad</h1>
                <p className="subtitle">Necesitamos una imagen de tu rostro</p>
            </span>

            <Lottie
                animationData={IdentitySelfie}
                play
                className={`${styles.animation} 'animation'`}
                loop
            />

            <p id={styles.instructions}>
                Ubicá tu cara dentro del marco y mira de frente a la cámara.{" "}
                <br />
                Es muy importante que cuentes con buena iluminación
            </p>

            <Button
                type="primary"
                color="orange-strong"
                text="Continuar"
                onClick={gyroPerm}
            />
        </div>
    );
};
