import React from "react";
import classes from "./DatosPersonales.module.scss";
import { DatosPersonalesHuella, UserPlaceholder } from "../../../assets";
import { Mapa } from "../..";
import { transformDate } from "../../../utils";
import { useState } from "react";

export default function DatosPersonales({ datos }) {
    const [showImage, setShowImage] = useState(true);
    return (
        <div className={classes.container}>
            <div className={classes.dashboard}>
                <section className={classes.top}>
                    {showImage ? (
                        <img
                            src={datos.imagePathSELFIE}
                            alt="Usuario"
                            onError={() => setShowImage(false)}
                            className={classes.img}
                        />
                    ) : (
                        <div>
                            <img src={UserPlaceholder} alt="Usuario" className={classes.img} />
                            <p>Ups!, no pudimos recuperar la selfie</p>
                        </div>
                    )}

                    <div className={classes.datos}>
                        <div>
                            <p>Fecha de emisión</p>
                            <p>20/11/2020</p>
                        </div>
                        <div>
                            <p>Numero de documento</p>
                            <p>{datos.usuarioNroDeDocumento}</p>
                        </div>
                        <div>
                            <p>Fecha de nacimiento</p>
                            <p>
                                {transformDate(datos.usuarioFechaDeNacimiento)}
                            </p>
                        </div>
                        <div>
                            <p>Sexo</p>
                            <p>
                                {datos.usuarioSexo === "F"
                                    ? "Femenino"
                                    : "Masculino"}
                            </p>
                        </div>
                        <div>
                            <p>Email</p>
                            <p>{datos.usuarioMail}</p>
                        </div>
                        <div>
                            <p>Celular</p>
                            <p>{datos.usuarioTelefonoCelular}</p>
                        </div>
                        <div>
                            <p>CUIT</p>
                            <p>{datos.usuarioCUIT}</p>
                        </div>
                        <div>
                            <p>NDI</p>
                            <p>12</p>
                        </div>
                    </div>
                    <div className={classes.login}>
                        <div>
                            <h4>Último Login</h4>
                            <p>{transformDate(datos.ultimaSesion)}</p>
                            <img src={DatosPersonalesHuella} alt="Huella" />
                        </div>
                        {datos.frecuencia && (
                            <div>
                                <h4>Frecuencia de Login</h4>
                                <p>
                                    {datos.frecuencia > 1
                                        ? `Login cada ${datos.frecuencia.toFixed(
                                            0
                                        )} días`
                                        : `Login ${datos.frecuencia.toFixed(2) * 100
                                        } veces por día`}
                                </p>
                            </div>
                        )}
                    </div>
                </section>

                <section className={classes.bottom}>
                    <div className={classes.datos}>
                        <div>
                            <p>Calle</p>
                            <p>{datos.domicilioCalle}</p>
                        </div>
                        <div>
                            <p>Provincia</p>
                            <p>{datos.provinciaNombre}</p>
                        </div>
                        <div>
                            <p>Número</p>
                            <p>{datos.domicilioCasa}</p>
                        </div>
                        <div>
                            <p>Localidad</p>
                            <p>{datos.localidadNombre}</p>
                        </div>
                        <div>
                            <p>Piso</p>
                            <p>{datos.domicilioPiso}</p>
                        </div>
                        <div>
                            <p>Latitud</p>
                            <p>{datos.domicilioLatitud}</p>
                        </div>
                        <div>
                            <p>Departamento</p>
                            <p>{datos.domicilioUnidad}</p>
                        </div>
                        <div>
                            <p>Longitud</p>
                            <p>{datos.domicilioLongitud}</p>
                        </div>
                        <div>
                            <p>Código Postal</p>
                            <p>{datos.domicilioCPA}</p>
                        </div>
                    </div>

                    <div className={classes.mapContainer}>
                        <Mapa
                            lat={datos.domicilioLatitud}
                            lng={datos.domicilioLongitud}
                            style={{
                                width: 430,
                                height: 250,
                                marginBottom: 28,
                            }}
                        />

                        <p className={classes.geolocalizacion}>
                            Geolocalizacion registrada
                        </p>
                    </div>
                </section>
            </div>


        </div>
    );
}
