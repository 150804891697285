import React from "react";
import { DniDorso, DniFrente, IdentityIcon } from "../../assets";
import {
    applyPolyfills,
    defineCustomElements,
} from "@microblink/blinkid-in-browser-sdk/ui/loader";
import ReactCardFlip from "react-card-flip";
import { GlobalContext } from "../../Context";
import { validationConstants } from "../../pages/Identity/ValidationSteps/validationConstants";

export const DocumentValidationStepScanDemo = ({ step, setStep }) => {
    const { setDniInfo } = React.useContext(GlobalContext);
    const licences = {
        localhost:
            "sRwAAAYJbG9jYWxob3N0r/lOPgo/w35CpJnWLGMQyTXXxQDxoA4wRDj8J3td1SDLiM3vTNNjnwwe8YNrMUQHvulHPDYwIWprflkPShL8bS0aHs+7zaiiMBhF27PX8bkkMllr2RFiy5SL6EhEPkTehWgpHhqcTqv/GLpC+aafeSSienJQ/nWKqPEBFJ95yBVInEQwWl61bYVdQQguwQFoYEPDLITnGOEe83EBgKKiiIhtuvMvxnDFbw==",
        "internal-ip":
            "sRwAAAYOMTkyLjE2OC4xMDAuNzW4p4gdVtoeYpeCXttD0MwWUmmYhqmww5aEoOsRE3Ru4FQehfIQA+RUQxeAoMw4WDIg3MJzoEOAa6FIXtPqyIBrrDie1L+2xUOOCVwNCWi/LK1L3efgf586jzOxRtc9mIEGbXUBtwlJNMr1VOzXE3Fbza5Vjd9xhLi6kE7VeyoWA6wTarmIOiWrzyCCC4uMJZrUOBQ10nUFzoBPk5/BN7VODwdgrkv0JWM=",
        "macro-eme-pwa-web":
            "sRwAAAYabWFjcm8tZW1lLXB3YS1kZXYuaG9vbGkubGFKkPOA7qjwMArshOf71NbAHchB+com8O4vfB8f3hRknOC5wLBvl9u1Law/fROJzGLhzH5AjTby1OygeFQt7031paHhlahybDub0IBQWShBDj5XAIaJYAwzGy02Noch9zYjNgrQLIq98r7jsXwf4GpyjmKnfEmetWh86Ddkr90aMpcuVXMVvjoN3fF0G/cNuIzalXqJZaOJBUWQIFqW61UpiF4ad2n7ewNBBA==",
        demo: "sRwAAAYNZGVtby5taWFpZC5tZW8DhKe8u+xoVjItM5n/wGqRtU54U3+5DU7+3OYbZmWFK3G/Smxd8vbfqM4yEMv0ORQPwiCCwm4MnqUDLoaoFaOnsUh2HpBFVJsdGXhex6YeSVdqJ2L9DWEaRjuHH/LCXjAnFRqeyFpATl4a6uDPvQNE4+1BOzH29hU1ScTwECzVd8hLuQGRmLj6N50/RI/J6QhMaThEImWa",
        demo2: "sRwAAAYOZGVtbzIubWlhaWQubWUlqOA4J+9lhUPGixxpjhr21eoHzEDGwJIHPQlJAiWTY0KT+2d2IToUr7SEsynKBLe5m8JJd1arudYA23VB3JnrBJuwDxI09MLBnDgZA34n7to9cJdNxPbA12qCDAlKbArkfwSYQdgUZee3WQBAC6NfcwPwm85syJ/WO171q8hL0pOWNgDR6fZj97h6lRKfkuhYTZswxSspva6zdBS9zv/oZiFKhlmONamC7VU=",
    };

    // Reference to the `<blinkid-in-browser>` custom web component
    const el = React.useRef(null);

    React.useEffect(() => {
        applyPolyfills().then(() => {
            defineCustomElements().then(() => {
                el.current.licenseKey =
                    window.location.origin === "https://demo.miaid.me"
                        ? licences.demo
                        : licences.localhost;
                el.current.recognizers = ["BlinkIdCombinedRecognizer"];

                // Engine location depends on the actual location of WebAssembly resources
                el.current.engineLocation =
                    window.location.origin + "/resourcesV5";
                el.current.workerLocation =
                    window.location.origin +
                    "/resourcesV5/BlinkIDWasmSDK.worker.min.js";

                /**
                 * Optional properties */
                el.allowHelloMessage = true;
                el.recognizerOptions = false;
                el.enableDrag = true;
                el.hideFeedback = false;
                el.hideLoadingAndErrorUi = false;
                el.scanFromCamera = true;
                el.scanFromImage = true;
                el.cameraId = null;
                el.translations = true;
                el.iconCameraDefault = true;
                el.iconCameraActive = true;
                el.current.iconGalleryDefault = true;
                el.current.iconGalleryActive = true;
                el.iconInvalidFormat = true;
                el.iconSpinner = true;

                el.current.translations = {
                    "camera-feedback-move-closer": "Acerca mas el documento",
                    "camera-feedback-scan-front": [
                        "Escaneando el frente",
                        "del documento",
                    ],
                    "camera-feedback-scan-back": [
                        "Escaneando el dorso",
                        "del documento",
                    ],
                    "camera-feedback-move-farther": "Aléjate un poco mas",
                    "modal-window-close": "Cerrar",
                    "action-message":
                        "Presiona el icono de la camara para iniciar",
                    "camera-feedback-barcode": [
                        "Acerque el codigo de barras",
                        "y mantengalo centrado",
                    ],
                    "camera-feedback-flip": "Ahora el dorso",
                };

                /**
                 * Events
                 */

                // Event emitted when UI component cannot initialize
                el.current.addEventListener("fatalError", (ev) => {
                    console.log("fatalError", ev.detail);
                });

                // Event emitted when UI component is ready to use
                el.current.addEventListener("ready", (ev) => {
                    console.log("ready", ev.detail);
                });

                // Event emitted in case of error during scan action
                el.current.addEventListener("scanError", (ev) => {
                    console.log("scanError", ev.detail);
                });

                // Event emitted when scan is successful
                el.current.addEventListener("scanSuccess", (ev) => {
                    console.log("scanSuccess", ev.detail.recognizer);
                    setDniInfo(ev.detail.recognizer);
                    setStep(validationConstants.SELFIE);
                });

                // Event emitted when UI component wants to display a feedback message to the user
                el.current.addEventListener("feedback", (ev) => {
                    console.log("feedback", ev.detail);
                });
            });
        });
    }, []);

    return (
        <>
            <h1>Validar D.N.I.</h1>
            <ReactCardFlip
                isFlipped={step === "DORSO_DNI"}
                flipSpeedFrontToBack={2}
            >
                <img src={DniFrente} alt="DniFrente" />
                <img src={DniDorso} alt="DniDorso" />
            </ReactCardFlip>

            <blinkid-in-browser ref={el}></blinkid-in-browser>
            <p>
                Ubica tu DNI, procurá que tenga buena iluminación y evitá los
                reflejos. <br />
                Es importante que sea el último DNI vigente
            </p>
        </>
    );
};
