import axiosPost from "./axiosPost";

export const rolGet = async (servicioId) => {
    const body = {
        service: "MIADemoRolGet",
        params: {
            servicioHooliId: servicioId,
        },
    };

    const result = await axiosPost(body);
    return result;
};
