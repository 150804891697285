import axiosPost from "./axiosPost";

export const usuarioCheck = async (dni) => {
    const body = {
        service: "MIADemoUsuarioCheck",
        params: {
            usuarioNroDeDocumento: dni,
        },
    };

    const result = await axiosPost(body);
    return result.status;
};
