import React from "react";
import classes from "./Spinner.module.scss";
import { Loading } from "../../assets/animations";
import Lottie from "react-lottie-player";

export default function Spinner() {
    return (
        <div className={classes.container}>
            <Lottie
                animationData={Loading}
                loop
                play
                className={classes.spinner}
            />
        </div>
    );
}
