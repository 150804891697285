import React, { useState } from "react";
import classes from "./DatosDeRegistro.module.scss";
import Mapa from "../../Mapa/Mapa";
import { transformDate } from "../../../utils";
import { Phone, UserPlaceholder } from "../../../assets";

export default function DatosDeRegistro({ datos }) {
    const [showSelfie, setShowSelfie] = useState(true);
    const [showFto, setShowFto] = useState(true);
    const [showFre, setShowFre] = useState(true);
    const [showDor, setShowDor] = useState(true);

    return (
        <div className={classes.container}>
            <div className={classes.images}>
                {showSelfie ? (
                    <img
                        src={datos.imagePathSELFIE}
                        alt="Selfie"
                        onError={() => setShowSelfie(false)}
                    />
                ) : (
                    <div>
                        <img src={UserPlaceholder} alt="Usuario" />
                        <p>Ups!, no pudimos recuperar la selfie</p>
                    </div>
                )}
                {showFto ? (
                    <img
                        src={datos.imagePathDNIFTO}
                        alt="DNI"
                        onError={() => setShowFto(false)}
                    />
                ) : (
                    <div>
                        <img src={UserPlaceholder} alt="Usuario" />
                        <p>Ups!, no pudimos recuperar la imagen</p>
                    </div>
                )}
                {showFre ? (
                    <img
                        src={datos.imagePathDNIFRE}
                        alt="Frente DNI"
                        className={classes.dni}
                        onError={() => setShowFre(false)}
                    />
                ) : (
                    <div>
                        <img src={UserPlaceholder} alt="Usuario" />
                        <p>Ups!, no pudimos recuperar la imagen</p>
                    </div>
                )}
                {showDor ? (
                    <img
                        src={datos.imagePathDNIDOR}
                        alt="Dorso DNI"
                        className={classes.dni}
                        onError={() => setShowDor(false)}
                    />
                ) : (
                    <div>
                        <img src={UserPlaceholder} alt="Usuario" />
                        <p>Ups!, no pudimos recuperar la imagen</p>
                    </div>
                )}
            </div>
            <div className={classes.bottom}>
                <h4>
                    <img src={Phone} alt="Phone" /> Usuario registrado con
                    dispositivo personal{" "}
                </h4>
                <div className={classes.datos}>
                    <div>
                        <p>Fecha y hora</p>
                        <p>{transformDate(datos.primeraSesionFH)}</p>
                    </div>
                    <div>
                        <p>Sistema operativo</p>
                        <p>{datos.primeraSesionOS}</p>
                    </div>
                    <div>
                        <p>Coordenadas</p>
                        <p>
                            {datos.primeraSesionLatitud}{" "}
                            {datos.primeraSesionLongitud}
                        </p>
                    </div>
                    <div>
                        <p>DEV Name</p>
                        <p>Iphone de Lorena</p>
                    </div>
                    <div>
                        <p>Marca del dispositivo</p>
                        <p>{datos.usuarioEquipoMarca}</p>
                    </div>
                    <div>
                        <p>SDK</p>
                        <p>4.1</p>
                    </div>
                    <div>
                        <p>Modelo</p>
                        <p>{datos.usuarioEquipoModelo}</p>
                    </div>
                    <div>
                        <p>FMR</p>
                        <p>00138021396F19940922</p>
                    </div>
                    <div>
                        <p>App version</p>
                        <p>{datos.usuarioAppVersion}</p>
                    </div>
                    <div>
                        <p>Alta</p>
                        <p>MIA Identity Rail</p>
                    </div>
                </div>
                <div className={classes.scores}>
                    <div>
                        <p>Score IDX</p>
                        <p>{datos.usuarioDNIMatchScore}</p>
                        <span></span>
                    </div>
                    <div>
                        <p>Score RENAPER</p>
                        <p>{datos.usuarioDNIMatchRENAPER}</p>
                        <span></span>
                    </div>
                    <div>
                        <p>FMR</p>
                        <p>{datos.usuarioDNIFMR}</p>
                        <span></span>
                    </div>
                </div>
                <div>
                    <Mapa
                        style={{ width: 430, height: 250, marginBottom: 15 }}
                    />
                    <p className={classes.geolocalizacion}>
                        Geolocalización al momento del registro
                    </p>
                </div>
            </div>
        </div>
    );
}
