import React from "react";
import classes from "./ModalTable.module.scss";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalTable(props) {
    const { open, setOpen, text, children } = props;
    if (!open) return <></>;

    return (
        <div className={classes.container}>
            <div className={classes.modal}>
                <div
                    onClick={() => setOpen(false)}
                    className={classes.closeIcon}
                >
                    <CloseIcon htmlColor="#38459D" sx={{ cursor: "pointer" }} />
                </div>
                <p>{text}</p>

                <div>{children}</div>
            </div>
        </div>
    );
}
