import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Layout } from "../../components";
import { GlobalContext } from "../../Context";

export default function Rechazado() {
    const { merchant } = useContext(GlobalContext);
    return (
        <Layout>
            <div className="cierre">
                <p className="merchant">{merchant?.merchantNombre}</p>
                <h2>Tu invitado rechazó la invitación</h2>
                <p>¡Gracias por ser parte de la experiencia digital!</p>
                <div>
                    <Button type="primary" color="blue">
                        <Link to="/user-connection/">Reintentar</Link>
                    </Button>
                    <Button type="primary" color="blue">
                        <Link to="/cierre">Volver al inicio</Link>
                    </Button>
                </div>
            </div>
        </Layout>
    );
}
