import React from "react";
import { Link } from "react-router-dom";
// Components
import { Button, Card, Layout } from "../../components";
import { AnimationDashboard } from "../../assets/animations";

export default function Home() {
    return (
        <Layout video={AnimationDashboard}>
            <Card color="blue">
                <h2>User Dashboard</h2>
                <p>
                    Toda la informacion en un solo tablero. <br />
                    Con el dashboard de MIA, vas a acceder a la información de
                    tus clientes <br />Y podras, a la vez, controlar y
                    administrar ingresos y egresos de personal
                </p>
                <Button type="primary" color="blue">
                    <Link to="/user-dashboard/landing">Comenzar</Link>
                </Button>
            </Card>
        </Layout>
    );
}
