import React, { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context";
import { Link, useNavigate } from "react-router-dom";
// Assets
import { IconRol } from "../../assets";
import {
    AnimationConexionEnviada,
    AnimationMIAID,
} from "../../assets/animations";
// Components
import { Button, Card, Layout, Input, Modal, Spinner } from "../../components";
// Services
import {
    connectionCheck,
    connectionIns,
    connectionUpd,
    rolGet,
} from "../../services";
import { v4 as uuidv4 } from "uuid";
import Lottie from "react-lottie-player";

export default function Usuario() {
    const navigate = useNavigate();
    const { merchant, userInfo, setToastInfo } = useContext(GlobalContext);

    const [roles, setRoles] = useState([]);
    const [rol, setRol] = useState("");
    const [loading, setLoading] = useState(false);
    const [esperando, setEsperando] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [uuid, setuuid] = useState(uuidv4());

    const handleError = useCallback(
        (text) => {
            setToastInfo({ open: true, text });
            setLoading(false);
            setEsperando(false);
        },
        [setToastInfo]
    );

    useEffect(() => {
        (async () => {
            setLoading(true);
            const { result, status } = await rolGet(null);
            if (status.code === 0) return handleError(status.errmsg);
            const roles = result.map((rol) => ({
                value: rol.rolId.toString(),
                name: rol.rolNombre,
            }));
            setRoles(roles);
            setRol(roles[0].value);
            setLoading(false);
        })();
    }, [handleError]);

    const handleSubmit = async () => {
        if (!merchant || !userInfo) return;
        if (!rol) return handleError("Por favor seleccione un rol");
        const response = await connectionIns(
            uuid,
            merchant.merchantId,
            userInfo.usuarioId,
            userInfo.usuarioNroDeDocumento,
            "S",
            parseInt(rol),
            null
        );
        if (response.code === 0) return handleError(response.errmsg);
        setEsperando(true);
    };

    const cancelConnection = () => {
        connectionUpd(uuid, "X", userInfo?.usuarioId);
        setuuid(uuidv4());
        setModalOpen(false);
        setEsperando(false);
    };

    useEffect(() => {
        if (!esperando) return;
        const checkConnection = setInterval(async () => {
            const response = await connectionCheck(uuid);

            const aprobado = response.conexionEstado === "A";
            const debeChequear = response.conexionEstado === "P";
            const rechazado = response.conexionEstado === "R";

            if (debeChequear) return;

            clearInterval(checkConnection);

            if (aprobado) return navigate("/user-sponsorship/aceptado");
            if (rechazado) return navigate("/user-sponsorship/rechazado");
        }, 3000);

        return () => {
            clearInterval(checkConnection);
        };
    }, [esperando, navigate, uuid]);

    if (!userInfo) return <></>;

    if (esperando)
        return (
            <Layout>
                <Modal
                    open={modalOpen}
                    setOpen={setModalOpen}
                    text={`¿Estás seguro que deseas cancelar la invitación a ${userInfo.usuarioNombres} ${userInfo.usuarioApellidos}?`}
                    handleClick={cancelConnection}
                    color="blue"
                />

                <Card color="blue">
                    <Lottie
                        animationData={AnimationConexionEnviada}
                        play
                        loop={true}
                    />
                    <p className="texto connection usuario">
                        Aguardá en esta pantalla que tu invitado acepte la
                        conexion, o continua navegando por nuestra Demo.
                        <br />
                        Si optás por continuar siempre podrás consultar el
                        estado de esta conexión desde el Dashboard.
                    </p>
                    <div className="buttons-container">
                        <Button type="primary" color="blue">
                            <Link to="/demo">Continuar navegando</Link>
                        </Button>
                        <Button
                            type="primary"
                            color="blue"
                            text="Cancelar conexión"
                            onClick={() => setModalOpen(true)}
                        />
                    </div>
                </Card>
            </Layout>
        );

    if (loading)
        return (
            <Layout>
                <Card color="blue">
                    <Spinner />
                </Card>
            </Layout>
        );

    return (
        <Layout>
            <Card color="blue">
                <div className="paso">2</div>
                <h2>¡Ya es usuario MIA!</h2>
                <p>
                    Vamos a conectar a {userInfo.usuarioNombres}{" "}
                    {userInfo.usuarioApellidos} con {merchant?.merchantNombre},
                    elige el rol con que quieres conectarlo
                </p>
                <Lottie animationData={AnimationMIAID} play loop={true} />
                <Input
                    name="rol"
                    label="Rol"
                    type="select"
                    value={rol}
                    setValue={setRol}
                    image={IconRol}
                    options={roles}
                />
                <Button
                    type="primary"
                    color="blue"
                    text="Conectar"
                    onClick={handleSubmit}
                />
            </Card>
        </Layout>
    );
}
