import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    LogoUserConnectionWhite,
    LogoUserSponsorshipWhite,
} from "../../assets";
import {
    DashboardLayout,
    DashboardLandingCard,
    Spinner,
} from "../../components";
import { GlobalContext } from "../../Context";
import { clienteUsuarioGet } from "../../services";

export default function Landing() {
    const { merchant } = useContext(GlobalContext);
    const [sponsorship, setSponsorship] = useState([]);
    const [connection, setConnection] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = useCallback(async () => {
        if (!merchant) return;
        setLoading(true);
        const [sponsorship, connection] = await Promise.all([
            await clienteUsuarioGet(merchant.merchantId, "S"),
            await clienteUsuarioGet(merchant.merchantId, "C"),
        ]);
        setSponsorship(sponsorship);
        setConnection(connection);
        setLoading(false);
    }, [merchant]);

    useEffect(() => {
        getData();
    }, [getData]);

    if (loading)
        return (
            <DashboardLayout title="User dashboard">
                <main id="landing">
                    <Spinner />
                </main>
            </DashboardLayout>
        );

    return (
        <DashboardLayout title="User dashboard">
            <main id="landing">
                <DashboardLandingCard
                    type="dark-blue"
                    title="User Sponsorship"
                    usuarios={sponsorship}
                    image={LogoUserSponsorshipWhite}
                    link="/user-dashboard/user-sponsorship"
                />
                <DashboardLandingCard
                    type="medium-blue"
                    title="User Connection"
                    usuarios={connection}
                    image={LogoUserConnectionWhite}
                    link="/user-dashboard/user-connection"
                />
            </main>
        </DashboardLayout>
    );
}
