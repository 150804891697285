import React from "react";
import { IdentityDNI } from "../../../assets/animations";
import { Button } from "../../../components";
import { validationConstants } from "./validationConstants";
import Lottie from "react-lottie-player";
import { CloudBig } from "../../../assets";

import styles from "./initialValidationScreen.module.scss";

export const InitialValidationScreen = ({ setStep }) => {
    return (
        <div>
            <img src={CloudBig} alt="cloud" className={styles.leftCloud} />

            <h1>
                Vamos a validar tu identidad para una experiencia más segura
            </h1>
            <Lottie
                animationData={IdentityDNI}
                play
                className={styles.dniAnimation}
                loop
            />
            <p>
                Sólo necesitas tener con vos tu DNI y estar en un lugar bien
                iluminado
            </p>

            <Button
                type="primary"
                color="orange-strong"
                text="Continuar"
                onClick={() => setStep(validationConstants.FRENTE_DNI)}
            />
        </div>
    );
};
