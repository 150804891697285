import axiosPost from "./axiosPost";

export const connectionCheck = async (uuid) => {
    const body = {
        service: "MIADemoConnectionCheck",
        params: {
            conexionUUID: uuid,
        },
    };

    const result = await axiosPost(body);
    return result.result[0];
};
