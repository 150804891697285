import axiosPost from "./axiosPost";

export const servicioGet = async (merchantId = null, usuarioId = null) => {
    const body = {
        service: "MIADemoServicioGet",
        params: {
            merchantId: merchantId,
            usuarioId: usuarioId,
            servicioEsMIA: "Y",
        },
    };

    const result = await axiosPost(body);
    return result;
};
