import React, { useEffect, useRef } from "react";
import classes from "./Layout.module.scss";
import { CloudBig, LogoMiaLayout } from "../../assets";
import HeaderIdentity from "../HeaderIdentity";
export default function Layout(props) {
    const { image, video, children, videoClass } = props;

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.play();
        }
    }, [videoRef]);

    const className = videoClass
        ? `${classes.image} ${classes[videoClass]} `
        : classes.image;

    return (
        <>  
        <HeaderIdentity />
        <main className={classes.main}>
            <div className={classes.cardContainer}>{children}</div>

            {image ? (
                <img src={image} alt="" className={classes.image} />
            ) : video ? (
                <video
                    src={video}
                    autoPlay
                    playsInline
                    loop
                    ref={videoRef}
                    className={className}
                />
            ) : (
                <img
                    src={CloudBig}
                    alt="cloud"
                    className={classes.rightCloud}
                />
            )}
        </main>
        </>
    );
}
