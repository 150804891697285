import React from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({
    value,
    children,
    redirect,
    condition,
}) {
    if ((condition && !value) || (!condition && value)) {
        return <Navigate to={redirect} replace />;
    }

    return children;
}
