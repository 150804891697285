import React from "react";
import { Link } from "react-router-dom";
import { AnimationIdentity } from "../../assets/animations";
import { Button, Card, Layout } from "../../components";

export default function Home() {
    return (
        <Layout >
            <Card color="blue">
                <h2>Identity Rail</h2>
                <p>
                    Ofrece una experiencia simple e innovadora a los clientes al
                    momento de validar su identidad en tu tienda online, a la
                    vez que tu negocio aumenta sus ventas de forma segura.{" "}
                </p>
                <Button type="primary" color="blue">
                    <Link to="/identity/compra">Comenzar</Link>
                </Button>
            </Card>
        </Layout>
    );
}
