import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Home.module.scss";
import { Button } from "../../components";
import { OrangeTick } from "../../assets";
import { AnimationHome } from "../../assets/animations";
import { GlobalContext } from "../../Context";
import { useSearchParams } from "react-router-dom";
export default function Home() {
    const { merchant } = useContext(GlobalContext);
    const [searchParams] = useSearchParams();
    const videoRef = useRef(null);
    const [screenWidth] = useState(window.innerWidth);

    //get version to load version of sdk blinkid
    const getVersion = () => {
        const versionSdkBlinkId = searchParams.get("v");
        localStorage.setItem(
            "versionBlinkIdSdk",
            JSON.stringify({ version: versionSdkBlinkId })
        );
    };
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.play();
        }
    }, [videoRef]);

    useEffect(() => {
        getVersion();
    }, []);

    return (
        <main className={classes.main}>
            <div className={classes.left}>
                <h4>¡Hola, {merchant?.merchantNombre}! v.6.0.2</h4>
                <h1>Tu identidad es tuya.</h1>
                <p>
                    MIA es la primera plataforma de Identidad digital que brinda
                    servicios a empresas, gobiernos y privados para su consumo
                    interno o para la prestación de servicios.{" "}
                </p>
                <h4>
                    <img src={OrangeTick} alt="tick" />
                    Segura, simple y personal
                </h4>
                <Button type="primary" color="blue">
                    <Link to={screenWidth < 500 ? "/identity" : "/demo"}>
                        Iniciar Demo
                    </Link>
                </Button>
            </div>

            <video
                src={AnimationHome}
                autoPlay
                playsInline
                loop
                ref={videoRef}
                className={classes.video}
            />
        </main>
    );
}
