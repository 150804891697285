import React from "react";
import classes from "./TableActionIcon.module.scss";

export default function TableActionIcon({ onClick, icon }) {
    return (
        <button className={classes.button} onClick={onClick}>
            <img src={icon} alt="Action" />
        </button>
    );
}
