import React from "react";
import { DocumentValidationStep } from "./DocumentValidationStep";
import { DocumentValidationStepScanDemo } from "../../../components/ScanDemo";

export function ContainerDocumentValidation({ step, setStep, setErrMsg }) {
    const getVersionToRender = () => {
        return JSON.parse(localStorage.getItem("versionBlinkIdSdk")).version;
    };

    if (getVersionToRender() === "demo") {
        return (
            <DocumentValidationStepScanDemo
                step={step}
                setStep={setStep}
                setErrMsg={setErrMsg}
            />
        );
    } else {
        return (
            <DocumentValidationStep
                step={step}
                setStep={setStep}
                setErrMsg={setErrMsg}
            />
        );
    }
}
