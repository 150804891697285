import axiosPost from "./axiosPost";

export const usuarioFotoGet = async (usuarioId) => {
    const body = {
        service: "UsuarioFotoGet",
        params: {
            usuarioId: usuarioId,
        },
    };

    const result = await axiosPost(body);
    return result.result[0];
};
