import React, { useState } from "react";

import { IdentityLayout } from "../../components";
import {
    InitialValidationScreen,
    SelfieInitialStep,
    SelfieValidationStep,
    ValidationProcessScreen,
    ValidationSucceededScreen,
    ErrorScreen,
    ContainerDocumentValidation,
} from "./ValidationSteps";

import { validationConstants } from "./ValidationSteps/validationConstants";

export default function Validar() {
    const [step, setStep] = useState(validationConstants.DNI);
    const [errMsg, setErrMsg] = useState(null);

    return (
        <IdentityLayout>
            {step === validationConstants.DNI && (
                <InitialValidationScreen setStep={setStep} />
            )}

            {(step === validationConstants.FRENTE_DNI ||
                step === validationConstants.DORSO_DNI) && (
                <ContainerDocumentValidation
                    step={step}
                    setStep={setStep}
                    setErrMsg={setErrMsg}
                />
            )}

            {step === validationConstants.SELFIE && (
                <SelfieInitialStep setStep={setStep} />
            )}

            {step === validationConstants.TAKE_SELFIE && (
                <SelfieValidationStep setStep={setStep} />
            )}

            {step === validationConstants.VALIDATING && (
                <ValidationProcessScreen
                    setStep={setStep}
                    setErrMsg={setErrMsg}
                />
            )}

            {step === validationConstants.VALIDATED && (
                <ValidationSucceededScreen />
            )}

            {step === validationConstants.ERROR && (
                <ErrorScreen errMsg={errMsg} setStep={setStep} />
            )}
        </IdentityLayout>
    );
}
