import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    DashboardLayout,
    DashboardDatosDeRegistro,
    DashboardDatosPersonales,
    DashboardIDL,
    Spinner,
} from "../../components";
import { usuarioFichaGet } from "../../services";

export default function Usuario() {
    const [ficha, setFicha] = useState("personales");
    const [datos, setDatos] = useState();
    const [loading, setLoading] = useState(true);

    const handleClick = (nuevaFicha) => () => setFicha(nuevaFicha);

    const params = new URLSearchParams(window.location.search);
    const usuario = params.get("usuario");
    const tipo = params.get("tipo");

    const getData = useCallback(async () => {
        if (!usuario) return;
        setLoading(true);
        const data = await usuarioFichaGet(usuario);
        setDatos(data);
        setLoading(false);
    }, [usuario]);

    const component = useMemo(() => {
        if (!datos) return <></>;
        switch (ficha) {
            case "personales":
                return (
                    <DashboardDatosPersonales datos={datos} getData={getData} />
                );
            case "registro":
                return (
                    <DashboardDatosDeRegistro datos={datos} getData={getData} />
                );
            case "IDL":
                return <DashboardIDL datos={datos} />;
            default:
                return <></>;
        }
    }, [ficha, datos, getData]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <DashboardLayout
            title={tipo === "C" ? "User connection" : "User sponsorship"}
        >
            <main id="usuario">
                <h4 className="title">
                    {datos
                        ? `${datos.usuarioNombres} ${datos.usuarioApellidos}`
                        : ""}
                </h4>
                <div className="fichas">
                    <div
                        className={
                            ficha === "personales" ? "ficha selected" : "ficha"
                        }
                        onClick={handleClick("personales")}
                    >
                        Datos personales
                    </div>
                    <div
                        className={
                            ficha === "registro" ? "ficha selected" : "ficha"
                        }
                        onClick={handleClick("registro")}
                    >
                        Datos de registro
                    </div>
                    <div
                        className={ficha === "IDL" ? "ficha selected" : "ficha"}
                        onClick={handleClick("IDL")}
                    >
                        IDL
                    </div>
                </div>
                {loading ? <Spinner /> : component}
            </main>
        </DashboardLayout>
    );
}
