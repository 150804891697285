import React from "react";
import classes from "./Input.module.scss";

export default function Input({
    type,
    name,
    image,
    label,
    placeholder,
    value,
    setValue,
    options,
}) {
    return (
        <div className={classes.container}>
            <label htmlFor={name}>{label}</label>
            <img src={image} alt={label} />
            {type === "text" && (
                <input
                    type={type}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => setValue(e.target.value)}
                    required
                    autoComplete="off"
                />
            )}
            {type === "select" && (
                <select
                    name={name}
                    autoComplete="off"
                    onChange={(e) => setValue(e.target.value)}
                >
                    {options?.map((option) => (
                        <option value={option.value} key={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
}
