import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../Context";
import classes from "./Demo.module.scss";
// Components
import { Button } from "../../components";
import Lottie from "react-lottie-player";
import Header from "../../components/Dashboard/Header/Header";
// Assets
import {
    CloudBig,
    LogoLogin,
    LogoUserConnection,
    LogoUserDashboard,
    LogoUserSponsorship,
    LogoIdentity,
    ArrowDown,
} from "../../assets";
import {
    DemoCardDashboard,
    DemoCardLogin,
    DemoCardUserConnection,
    DemoCardUserSponsorship,
    DemoCardIdentity,
} from "../../assets/animations";

export default function Demo() {
    const navigate = useNavigate();

    const falseValues = {
        sponsorship: false,
        connection: false,
        login: false,
        dashboard: false,
        identity: false,
    };

    const [playing, setPlaying] = useState(falseValues);
    const [keyPress, setKeyPress] = useState(false);

    let timeout;

    const handleHover = (key) => () => {
        if (playing[key] || timeout) return;
        timeout = setTimeout(() => {
            setPlaying({ ...falseValues, [key]: true });
        }, 1000);
    };

    
    useEffect(() => {
        document.addEventListener("keydown", ({ key }) => {
            console.log("Key press", key);
            if (key === "t") {
                setKeyPress(true);
            } else {
                setKeyPress(false);
            }
        });

        // remove on unload
         return () => {
             document.removeEventListener("keydown", ({ key }) => {
                 if (key === "t") {
                     setKeyPress(true);
                 } else {
                     setKeyPress(false);
                 }
             });
         };
    }, []);

    const handleBlur = () => {
        clearTimeout(timeout);
        setPlaying(falseValues);
    };

    const handleClick = (route) => () => navigate(route);

    return (
        <>
            <Header />
            <main className={classes.main}>
                <img src={CloudBig} alt="cloud" className={classes.leftCloud} />
                <h1 className={classes.title}>¡Bienvenido!</h1>
                <h4 className={classes.subtitle}>
                    Elegí tu opción y comenzá la demo
                </h4>

                <div className={classes.buttonsContainer}>
                    <div
                        onMouseOver={handleHover("sponsorship")}
                        onMouseLeave={handleBlur}
                    >
                        {playing.sponsorship && (
                            <Lottie
                                animationData={DemoCardUserSponsorship}
                                play
                                loop={false}
                                className={classes.video}
                                onClick={handleClick("/user-sponsorship")}
                            />
                        )}
                        <span className={classes.imageCard}>
                            <img
                                src={LogoUserSponsorship}
                                alt="User sponsorship"
                            />
                            <span className={classes.arrow}>
                                <img src={ArrowDown} alt="User sponsorship" />
                            </span>
                        </span>
                        <Button
                            type="primary"
                            color="orange-strong"
                            customClass={"buttonsCustom"}
                        >
                            <Link to="/user-sponsorship">User Sponsorship</Link>
                        </Button>
                    </div>
                    <div
                        onMouseOver={handleHover("connection")}
                        onMouseLeave={handleBlur}
                    >
                        {playing.connection && (
                            <Lottie
                                animationData={DemoCardUserConnection}
                                play
                                className={classes.video}
                                loop={false}
                                onClick={handleClick("/user-connection")}
                            />
                        )}
                        <span className={classes.imageCard}>
                            <img
                                src={LogoUserConnection}
                                alt="User connection"
                            />
                            <span className={classes.arrow}>
                                <img src={ArrowDown} alt="User sponsorship" />
                            </span>
                        </span>
                        <Button
                            type="primary"
                            color="orange-strong"
                            customClass={"buttonsCustom"}
                        >
                            <Link to="/user-connection">User Connection</Link>
                        </Button>
                    </div>
                    <div
                        onMouseOver={handleHover("login")}
                        onMouseLeave={handleBlur}
                    >
                        {playing.login && (
                            <Lottie
                                animationData={DemoCardLogin}
                                play
                                className={classes.video}
                                loop={false}
                                onClick={handleClick("/login")}
                            />
                        )}
                        <span className={classes.imageCard}>
                            <img src={LogoLogin} alt="Login" />
                            <span className={classes.arrow}>
                                <img src={ArrowDown} alt="User sponsorship" />
                            </span>
                        </span>
                        <Button
                            type="primary"
                            color="orange-strong"
                            customClass={"buttonsCustom"}
                        >
                            <Link to="/login">Login by MIA</Link>
                        </Button>
                    </div>
                    <div
                        onMouseOver={handleHover("identity")}
                        onMouseLeave={handleBlur}
                    >
                        {playing.identity && (
                            <Lottie
                                animationData={DemoCardIdentity}
                                play
                                className={classes.video}
                                loop={false}
                                onClick={handleClick("/identity")}
                            />
                        )}
                        <span className={classes.imageCard}>
                            <img src={LogoIdentity} alt="Identity Rail" />
                            <span className={classes.arrow}>
                                <img src={ArrowDown} alt="User sponsorship" />
                            </span>
                        </span>
                        <Button
                            type="primary"
                            color="orange-strong"
                            customClass={"buttonsCustom"}
                        >
                            <Link to={keyPress ? "/gob-tucuman" : "/identity"}>
                                Identity Rail
                            </Link>
                        </Button>
                    </div>
                    <div
                        onMouseOver={handleHover("dashboard")}
                        onMouseLeave={handleBlur}
                    >
                        {playing.dashboard && (
                            <Lottie
                                animationData={DemoCardDashboard}
                                play
                                className={classes.video}
                                loop={false}
                                onClick={handleClick("/user-dashboard")}
                            />
                        )}
                        <span className={classes.imageCard}>
                            <img src={LogoUserDashboard} alt="User dashboard" />
                            <span className={classes.arrow}>
                                <img src={ArrowDown} alt="User sponsorship" />
                            </span>
                        </span>
                        <Button
                            type="primary"
                            color="orange-strong"
                            customClass={"buttonsCustom"}
                        >
                            <Link to="/user-dashboard">User Dashboard</Link>
                        </Button>
                    </div>
                </div>
            </main>
        </>
    );
}
