import React from "react";
import { Link } from "react-router-dom";
import { IdentityValidado } from "../../../assets/animations";
import { Button } from "../../../components";
import Lottie from "react-lottie-player";
import { ValidationBadges } from "./ValidationBadges";

import classes from "./ValidationSucceeded.module.scss";

export const ValidationSucceededScreen = () => {
    return (
        <div className={classes.validationContainer}>
            <Lottie
                animationData={IdentityValidado}
                play
                className="animation"
            />

            <h1 className="expand">
                ¡Tu identidad ha sido validada con éxito!
            </h1>
            <p id={classes.message}>
                Gracias por ser parte de esta experiencia
            </p>

            <ValidationBadges success={{
                antispoofing: true,
                fotografia: true,
                liveness: true,
            }} />

            <Button type="primary" color="orange-strong">
                <Link to="/identity/usuario">Continuar</Link>
            </Button>
        </div>
    );
};
