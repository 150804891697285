export const formatDNI = (value, setDNI) => {
    if (/[^0-9]/.test(value[value.length - 1]) && value !== "") return;
    const text = value.replace(/\./g, "");
    if (text.length < 4) setDNI(text);
    if (text.length === 4) setDNI(`${text[0]}.${text.slice(1)}`);
    if (text.length === 5) setDNI(`${text.slice(0, 2)}.${text.slice(2)}`);
    if (text.length === 6) setDNI(`${text.slice(0, 3)}.${text.slice(3)}`);
    if (text.length === 7)
        setDNI(`${text.slice(0, 1)}.${text.slice(1, 4)}.${text.slice(4)}`);
    if (text.length === 8)
        setDNI(`${text.slice(0, 2)}.${text.slice(2, 5)}.${text.slice(5)}`);
};
