import React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { ErrorDaon } from "../../../assets";
import classes from "./index.module.scss";
import { validationConstants } from "../../Identity/ValidationSteps/validationConstants";
function ErrorDaonPage({ setStep }) {
    return (
        <Grid container display={"flex"} flexDirection={"column"}>
            <Grid item rowSpacing={3} mb={3}>
                <h1 className={classes.title}>
                    {" "}
                    No pudimos reconocer tu rostro
                </h1>
                <p className={classes.p}>Por favor, intenalo de nuevo</p>
            </Grid>
            <Grid item>
                <img src={ErrorDaon} alt="error-daon" className={classes.img} />
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    className={classes.btn}
                    onClick={() => setStep(validationConstants.TAKE_SELFIE)}
                >
                    Reintentar
                </Button>
            </Grid>
        </Grid>
    );
}

export default ErrorDaonPage;
