import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Context";
// Components
import { Button, Layout } from "../../components";

export default function Aceptado() {
    const { merchant } = useContext(GlobalContext);
    return (
        <Layout>
            <div className="cierre">
                <p className="merchant">{merchant?.merchantNombre},</p>
                <h2>
                    Tu invitado aceptó la invitación y ya es parte de tu empresa
                </h2>
                <p>¡Gracias por ser parte de la experiencia digital!</p>
                <Button type="primary" color="blue">
                    <Link to="/cierre">Volver al inicio</Link>
                </Button>
            </div>
        </Layout>
    );
}
