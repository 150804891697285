import React, { useContext, useEffect } from "react";
import ReactCardFlip from "react-card-flip";

import { validationConstants } from "../ValidationSteps/validationConstants";

import * as BlinkIDSDK6 from "../../../blinkid/blinkid-sdk";
import * as BlinkIDSDK5 from "../../../blinkidV5/blinkid-sdk";

import { GlobalContext } from "../../../Context";

import { DniDorso, DniFrente, IdentityIcon } from "../../../assets";

import styles from "./DocumentValidationStep.module.scss";

export const DocumentValidationStep = ({ step, setStep, setErrMsg }) => {
    const licences = {
        localhost:
            "sRwAAAYJbG9jYWxob3N0r/lOPgo/w35CpJnWLNs8ZT54sLL65J4cnxQf4gBqOj9Yh5Ke6yp2t0z/G7ZV4d1i1lUy0lSlhL5YoD7DfCrgbiNLnKX4otoIJLDPwlzjYD/XnjtieONg5LrBE3S2WI9/PgmTRtSgbm1D4CP8ds3aFi/Eyl5SD/fD3ryytxeVz7qA8Op51l0gKMpox0+9DL7KSlkumPD6ONihwlRmZdA8d1F5hJ21u4fN4g==",
        "internal-ip":
            "sRwAAAYOMTkyLjE2OC4xMDAuNzW4p4gdVtoeYpeCXttD0MwWUmmYhqmww5aEoOsRE3Ru4FQehfIQA+RUQxeAoMw4WDIg3MJzoEOAa6FIXtPqyIBrrDie1L+2xUOOCVwNCWi/LK1L3efgf586jzOxRtc9mIEGbXUBtwlJNMr1VOzXE3Fbza5Vjd9xhLi6kE7VeyoWA6wTarmIOiWrzyCCC4uMJZrUOBQ10nUFzoBPk5/BN7VODwdgrkv0JWM=",
        "macro-eme-pwa-web":
            "sRwAAAYabWFjcm8tZW1lLXB3YS1kZXYuaG9vbGkubGFKkPOA7qjwMArshOf71NbAHchB+com8O4vfB8f3hRknOC5wLBvl9u1Law/fROJzGLhzH5AjTby1OygeFQt7031paHhlahybDub0IBQWShBDj5XAIaJYAwzGy02Noch9zYjNgrQLIq98r7jsXwf4GpyjmKnfEmetWh86Ddkr90aMpcuVXMVvjoN3fF0G/cNuIzalXqJZaOJBUWQIFqW61UpiF4ad2n7ewNBBA==",
        demo: "sRwAAAYNZGVtby5taWFpZC5tZW8DhKe8u+xoVjItM5n/wGqRtU54U3+5DU7+3OYbZmWFK3G/Smxd8vbfqM4yEMv0ORQPwiCCwm4MnqUDLoaoFaOnsUh2HpBFVJsdGXhex6YeSVdqJ2L9DWEaRjuHH/LCXjAnFRqeyFpATl4a6uDPvQNE4+1BOzH29hU1ScTwECzVd8hLuQGRmLj6N50/RI/J6QhMaThEImWa",
        demo2: "sRwAAAYOZGVtbzIubWlhaWQubWUlqOA4J+9lhUPGixxpjhr21eoHzEDGwJIHPQlJAiWTY0KT+2d2IToUr7SEsynKBLe5m8JJd1arudYA23VB3JnrBJuwDxI09MLBnDgZA34n7to9cJdNxPbA12qCDAlKbArkfwSYQdgUZee3WQBAC6NfcwPwm85syJ/WO171q8hL0pOWNgDR6fZj97h6lRKfkuhYTZswxSspva6zdBS9zv/oZiFKhlmONamC7VU=",
    };

    const dniFeed = React.useRef(null);
    const dniCanvas = React.useRef(null);
    const drawContext = React.useRef(null);
    const BlinkIDSDK = React.useRef(null);
    const { setDniInfo } = useContext(GlobalContext);

    const [feedbackMsg, setFeedbackMsg] = React.useState("Cargando...");

    const getVersionBlinkIdSdk = () => {
        return "v5";
    };

    /**
     * This function will make sure that coordinate system associated with detectionResult
     * canvas will match the coordinate system of the image being recognized.
     */
    function applyTransform(transformMatrix) {
        const canvasAR = dniCanvas.current.width / dniCanvas.current.height;
        const videoAR =
            dniFeed.current.videoWidth / dniFeed.current.videoHeight;

        let xOffset = 0;
        let yOffset = 0;
        let scaledVideoHeight = 0;
        let scaledVideoWidth = 0;

        if (canvasAR > videoAR) {
            // pillarboxing: https://en.wikipedia.org/wiki/Pillarbox
            scaledVideoHeight = dniCanvas.current.height;
            scaledVideoWidth = videoAR * scaledVideoHeight;
            xOffset = (dniCanvas.current.width - scaledVideoWidth) / 2.0;
        } else {
            // letterboxing: https://en.wikipedia.org/wiki/Letterboxing_(filming)
            scaledVideoWidth = dniCanvas.current.width;
            scaledVideoHeight = scaledVideoWidth / videoAR;
            yOffset = (dniCanvas.current.height - scaledVideoHeight) / 2.0;
        }

        // first transform canvas for offset of video preview within the HTML video element (i.e. correct letterboxing or pillarboxing)
        drawContext.current.translate(xOffset, yOffset);
        // second, scale the canvas to fit the scaled video
        drawContext.current.scale(
            scaledVideoWidth / dniFeed.current.videoWidth,
            scaledVideoHeight / dniFeed.current.videoHeight
        );

        // finally, apply transformation from image coordinate system to
        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
        drawContext.current.transform(
            transformMatrix[0],
            transformMatrix[3],
            transformMatrix[1],
            transformMatrix[4],
            transformMatrix[2],
            transformMatrix[5]
        );
    }

    function clearDrawCanvas() {
        dniCanvas.current.width = dniCanvas.current.clientWidth;
        dniCanvas.current.height = dniCanvas.current.clientHeight;

        drawContext.current.clearRect(
            0,
            0,
            dniCanvas.current.width,
            dniCanvas.current.height
        );
    }

    function setupColor(displayable) {
        let color = "#FFFF00FF";

        if (displayable.detectionStatus === 0) {
            color = "#FF0000FF";
        } else if (displayable.detectionStatus === 1) {
            color = "#00FF00FF";
        }

        drawContext.current.fillStyle = color;
        drawContext.current.strokeStyle = color;
        drawContext.current.lineWidth = 5;
    }

    function setupMessage(displayable) {
        switch (displayable.detectionStatus) {
            case BlinkIDSDK.current.DetectionStatus.Fail:
                setFeedbackMsg("Error en la detección. Reintentando.");
                break;
            case BlinkIDSDK.current.DetectionStatus.Success:
            case BlinkIDSDK.current.DetectionStatus.FallbackSuccess:
                setFeedbackMsg("Analizando documento. Espere por favor.");
                break;
            case BlinkIDSDK.current.DetectionStatus.CameraAtAngle:
                setFeedbackMsg("Ajustá el ángulo");
                break;
            case BlinkIDSDK.current.DetectionStatus.CameraTooHigh:
                setFeedbackMsg("Acercá el documento");
                break;
            case BlinkIDSDK.current.DetectionStatus.CameraTooNear:
            case BlinkIDSDK.current.DetectionStatus.DocumentTooCloseToEdge:
            case BlinkIDSDK.current.DetectionStatus.Partial:
                setFeedbackMsg("Alejá el documento");
                break;
            default:
                console.warn(
                    "Unhandled detection status!",
                    displayable.detectionStatus
                );
        }
    }

    /**
     * Utility functions for drawing detected quadrilateral onto canvas.
     */
    function drawQuad(quad) {
        clearDrawCanvas();
        // Based on detection status, show appropriate color and message
        setupColor(quad);
        setupMessage(quad);

        applyTransform(quad.transformMatrix);
        drawContext.current.beginPath();
        drawContext.current.moveTo(quad.topLeft.x, quad.topLeft.y);
        drawContext.current.lineTo(quad.topRight.x, quad.topRight.y);
        drawContext.current.lineTo(quad.bottomRight.x, quad.bottomRight.y);
        drawContext.current.lineTo(quad.bottomLeft.x, quad.bottomLeft.y);
        drawContext.current.closePath();
        drawContext.current.stroke();
    }

    async function startScan(sdk) {
        // Initialize canvas context
        const versionSDKBlinkId = getVersionBlinkIdSdk();

        let combinedGenericIDRecognizer = null;
        drawContext.current = dniCanvas.current.getContext("2d");
        // 1. Create a recognizer objects which will be used to recognize single image or stream of images.
        //
        // GenericCombine ID Recognizer - scan ID documents on both sides
        console.log("Esto es el constructor de blinkid", BlinkIDSDK);
        if (versionSDKBlinkId === "v5") {
            combinedGenericIDRecognizer =
                await BlinkIDSDK.current.createBlinkIdCombinedRecognizer(sdk);
        } else {
            combinedGenericIDRecognizer =
                await BlinkIDSDK.current.createBlinkIdMultiSideRecognizer(sdk);
        }

        // Retrieve current settings
        const settings = await combinedGenericIDRecognizer.currentSettings();
        // Update desired settings
        settings["returnFullDocumentImage"] = true;
        settings["returnFaceImage"] = true;

        // Apply settings
        await combinedGenericIDRecognizer.updateSettings(settings);

        // Create a callbacks object that will receive recognition events, such as detected object location etc.
        const callbacks = {
            onQuadDetection: (quad) => drawQuad(quad),
            onDetectionFailed: () => setFeedbackMsg("Error en la detección"),

            // This callback is required for combined experience.
            onFirstSideResult: () => {
                clearDrawCanvas();
                setFeedbackMsg(
                    "Da vuelta el documento y acercá el dorso del mismo"
                );

                setStep(validationConstants.DORSO_DNI);
            },
        };

        // 2. Create a RecognizerRunner object which orchestrates the recognition with one or more
        //    recognizer objects.

        const recognizerRunner =
            await BlinkIDSDK.current.createRecognizerRunner(
                // SDK instance to use
                sdk,
                // List of recognizer objects that will be associated with created RecognizerRunner object
                [combinedGenericIDRecognizer],
                // [OPTIONAL] Should recognition pipeline stop as soon as first recognizer in chain finished recognition
                false,
                // Callbacks object that will receive recognition events
                callbacks
            );

        // 3. Create a VideoRecognizer object and attach it to HTMLVideoElement that will be used for displaying the camera feed
        const videoRecognizer =
            await BlinkIDSDK.current.VideoRecognizer.createVideoRecognizerFromCameraStream(
                dniFeed.current,
                recognizerRunner
            );

        setFeedbackMsg("Acerca el frente de tu DNI");

        // 4. Start the recognition and get results from callback
        // updateScanFeedback("Acercá el frente del documento", true)
        try {
            videoRecognizer.startRecognition(
                // 5. Obtain the results
                async (recognitionState) => {
                    let errorMsg = null;
                    console.log("recognitionState", recognitionState);
                    if (!videoRecognizer) return;

                    // Pause recognition before performing any async operation
                    videoRecognizer.pauseRecognition();

                    if (
                        recognitionState ===
                        BlinkIDSDK.current.RecognizerResultState.Empty
                    )
                        errorMsg = 4000001;

                    const blinkIdResult =
                        await combinedGenericIDRecognizer.getResult();

                    if (
                        blinkIdResult.state ===
                        BlinkIDSDK.current.RecognizerResultState.Empty
                    )
                        errorMsg = 4000001;

                    if (
                        blinkIdResult.state ===
                        BlinkIDSDK.current.RecognizerResultState.Uncertain
                    )
                        errorMsg = 4000002;

                    // TODO VER ESTO
                    // BlinkIDSDK.RecognizerResultState.Uncertain

                    console.log(
                        "Esto es lo que se setea en dniInfo",
                        blinkIdResult
                    );
                    setDniInfo(blinkIdResult);

                    // 6. Release all resources allocated on the WebAssembly heap and associated with camera stream

                    // Release browser resources associated with the camera stream
                    videoRecognizer?.releaseVideoFeed();

                    // Release memory on WebAssembly heap used by the RecognizerRunner
                    recognizerRunner?.delete();

                    // Release memory on WebAssembly heap used by the recognizer
                    combinedGenericIDRecognizer?.delete();

                    // Clear any leftovers drawn to canvas
                    clearDrawCanvas();

                    if (errorMsg) {
                        setErrMsg(errorMsg);
                        setStep(validationConstants.ERROR);
                    } else setStep(validationConstants.SELFIE);
                }
            );
        } catch (error) {
            setStep(validationConstants.ERROR);
            console.error(
                "Error during initialization of VideoRecognizer:",
                error
            );
        }
    }

    useEffect(() => {
        const versionSDKBlinkId = getVersionBlinkIdSdk();
        // Check if browser has proper support for WebAssembly
        BlinkIDSDK.current =
            versionSDKBlinkId === "v5" ? BlinkIDSDK5 : BlinkIDSDK6;
        if (!BlinkIDSDK.current.isBrowserSupported()) {
            setStep(validationConstants.ERROR);
            return;
        }

        let licenseKey = licences.localhost;
        if (window.location.origin === "https://demo.miaid.me") {
            licenseKey = licences.demo;
        }


        const loadSettings = new BlinkIDSDK.current.WasmSDKLoadSettings(
            licenseKey
        );

        loadSettings.allowHelloMessage = true;

        loadSettings.engineLocation =
            versionSDKBlinkId === "v5"
                ? window.location.origin + "/resourcesV5"
                : window.location.origin + "/resources";

        BlinkIDSDK.current.loadWasmModule(loadSettings).then(
            (sdkInstance) => {
                startScan(sdkInstance);
            },
            (error) => {
                setStep(validationConstants.ERROR);
                console.error("Error al cargar SDK!", error);
            }
        );
    }, []);

    return (
        <div className={styles.container}>
            <span className={styles.titleContainer}>
                <h1>Validar D.N.I.</h1>
                {step === validationConstants.FRENTE_DNI ? (
                    <p className="subtitle">Comenzamos con el frente</p>
                ) : (
                    <p className="subtitle">Ahora, vamos con el dorso</p>
                )}
            </span>

            <ReactCardFlip
                isFlipped={step === validationConstants.DORSO_DNI}
                flipSpeedFrontToBack={2}
            >
                <img src={DniFrente} alt="DniFrente" />

                <img src={DniDorso} alt="DniDorso" />
            </ReactCardFlip>

            <div id="dni-screen-scanning" className={styles.camCapture}>
                <div
                    className={
                        step === validationConstants.FRENTE_DNI
                            ? "videoContainer"
                            : "videoContainer flip"
                    }
                >
                    <video ref={dniFeed} id="dni-feed" playsInline></video>
                </div>
                <canvas ref={dniCanvas} id="dni-feedback"></canvas>
                <p id="camera-dni-guides">
                    <span style={{ background: "#24224F" }}>{feedbackMsg}</span>
                </p>
            </div>

            <p id={styles.docInstructions}>
                Ubica tu DNI en la cámara, procurá que tenga buena iluminación y
                evitá los reflejos. <br />
                Es importante que sea el último DNI vigente
            </p>
        </div>
    );
};
