import React from "react";
import logoSamsung from "../../../assets/samsung-logo.png";
import styles from "./Compra.module.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Button, Link, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Carousel from "../../../components/Carousel/Carousel";
import MIALogo from "../../../assets/MIA-logo.svg";
export default function HomeSamsung() {
    //Hooks
    const navigate = useNavigate();

    //Handles
    const handleClick = () => navigate("/identity/validar");

    //Renders
    const renderBreadcrumbsLink = () => (
        <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            sx={{
                "& .MuiBreadcrumbs-li": {
                    padding: "0em",
                },
                "& .MuiBreadcrumbs-separator": {
                    margin: 0,
                },
                "& .MuiBreadcrumbs-root": {
                    marginLeft: "5em",
                },
            }}
        >
            <Link underline="hover" color="inherit" href="/">
                Home
            </Link>
            <Link
                underline="hover"
                color="inherit"
                href="/material-ui/getting-started/installation/"
            >
                Dispositivos Móviles
            </Link>
            <Link
                underline="hover"
                color="inherit"
                href="/material-ui/getting-started/installation/"
            >
                Smartphones
            </Link>
            <Link
                underline="hover"
                color="inherit"
                href="/material-ui/getting-started/installation/"
            >
                Galaxy Z Fold4
            </Link>
        </Breadcrumbs>
    );

    return (
        <section className={styles.section}>
            <header>
                <nav className={styles.nav}>
                    <ul>
                        <h1>Evan Tech</h1>
                        <div className={styles.groupNav1}>
                            <li>Móviles</li>
                            <li>TV & Audio</li>
                            <li>Electrodomésticos</li>
                            <li>Informática</li>
                        </div>
                        <div className={styles.groupNav2}>
                            <li>Ofertas</li>
                            <li>Sumá y ahorrá</li>
                            <li>Plan Canje Samsung</li>
                            <li>Soporte </li>
                            <li>Empresas</li>
                        </div>
                        <div className={styles.iconNav}>
                            <li>
                                <LocationOnIcon color="primary" />{" "}
                            </li>
                            <li>
                                <PersonOutlineOutlinedIcon />{" "}
                            </li>
                            <li>
                                <ShoppingCartOutlinedIcon />
                            </li>
                            <li>
                                <SearchOutlinedIcon />
                            </li>
                        </div>
                    </ul>
                </nav>

                {/*Banner */}
                <div className={styles.bannerContainer}>
                    <div className={styles.banner}>
                        <div className={styles.groupBanner}>
                            <div className={styles.titleBannerContainer}>
                                <h1>Celular Galaxy Z Fold</h1>
                            </div>
                            <h3>$399.999</h3>
                        </div>
                    </div>
                </div>
            </header>

            <main>
                <div className={styles.breadcrumbContainer}>
                    {renderBreadcrumbsLink()}
                </div>

                <Grid container>
                    <Grid item xs={12} md={6} lg={6}>
                        <div className={styles.containerCarousel}>
                            <Carousel>
                                <div className={styles.containerImg}>
                                    <img
                                        src="https://samsungar.vtexassets.com/arquivos/ids/183269-800-auto?width=500&height=auto&aspect=true"
                                        alt="foto"
                                        height={400}
                                    />
                                </div>
                            </Carousel>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={5} lg={5}>
                        <div className={styles.titleProduct}>
                            <h1>Galaxy Z Fold4 Phantom Black 256gb </h1>
                            <small>SM-F936BZKLARO</small>
                        </div>

                        <div className={styles.groupText}>
                            <img
                                src="https://samsungar.vtexassets.com/_v/public/assets/v1/published/samsungbr.samsung-store@1.371.1/public/react/25f8ffb189b1c21a47f89b49ae2923a5.png"
                                alt="hoy"
                            />
                            <div>
                                <h4>¡Recibilo gratis Hoy!</h4>
                                <small>Comprando hoy hasta las 11:30 AM.</small>
                            </div>
                        </div>

                        <div className={styles.buyContainer}>
                            <h3>Galaxy Z Fold4 Phantom Black 256gb </h3>
                            <small>Silver | 256gb</small>
                            <div className={styles.line}></div>
                            <div className={styles.buyTextGroup}>
                                <h5>Hasta 6 cuotas sin interés.</h5>
                                <h1>$399.999</h1>
                            </div>

                            <div>
                                <button
                                    className={styles.buttonMIA}
                                    onClick={() => handleClick()}
                                >
                                    Comprar y validar identidad con{" "}
                                    <img src={MIALogo} alt="logo mia" />
                                </button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </main>
        </section>
    );
}
