import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context";
import { useNavigate } from "react-router-dom";
// Assets
import { IconDNI } from "../../assets";
// Components
import { Button, Card, Input, Layout, Spinner } from "../../components";
// Services
import { usuarioCheck } from "../../services/usuarioCheck";
// Types
import { clienteUsuarioGet, usuarioFichaGet } from "../../services";
import { formatDNI } from "../../utils";

export default function Paso1() {
    const navigate = useNavigate();
    const { merchant, setUserInfo, setToastInfo } = useContext(GlobalContext);

    const [DNI, setDNI] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUserInfo(null);
    }, [setUserInfo]);

    const handleError = (text) => {
        setToastInfo({ open: true, text });
        setLoading(false);
    };

    const handleSubmit = async () => {
        if (!merchant) return;
        if (DNI === "") return handleError("Por favor ingrese un DNI");
        const formattedDNI = DNI.replace(/\./g, "");
        if (formattedDNI.length < 7 || formattedDNI.length > 8)
            return handleError("Por favor ingrese un DNI valido");

        setLoading(true);

        // Revisa si el usuario existe
        const usuarioCheckResponse = await usuarioCheck(formattedDNI);
        if (usuarioCheckResponse.code === 0)
            return handleError(usuarioCheckResponse.errmsg);

        if (usuarioCheckResponse.errmsg === "Usuario inexistente")
            return navigate(`/user-sponsorship/no-usuario?dni=${formattedDNI}`);

        const usuarioId = usuarioCheckResponse.id;

        // Revisa si el usuario esta relacionado al merchant y navega a la pagina de usuario de ser asi
        const clienteUsuarioGetResponse = await clienteUsuarioGet(
            merchant.merchantId,
            "S",
            usuarioId
        );

        if (
            clienteUsuarioGetResponse.filter(
                (user) => user.relacionStatus !== "Rechazada"
            ).length > 0
        ) {
            setUserInfo(clienteUsuarioGetResponse[0]);
            return navigate("/user-sponsorship/usuario");
        }

        // Navegar a pantalla de usuario no relacionado
        const usuarioFichaGetResponse = await usuarioFichaGet(usuarioId);
        setUserInfo(usuarioFichaGetResponse);
        return navigate("/user-sponsorship/usuario-sin-rol");
    };

    const handleChange = (value) => formatDNI(value, setDNI);

    if (loading) {
        return (
            <Layout>
                <Card color="blue">
                    <Spinner />
                </Card>
            </Layout>
        );
    }
    return (
        <Layout>
            <Card color="blue">
                <div className="paso">1</div>
                <h2>User Sponsorship</h2>
                <p className="texto sponsorship paso1">
                    Elegí una persona de tu equipo para realizar la prueba y
                    seguí los pasos que te indicamos a continuación.
                </p>
                <Input
                    name="dni"
                    label="DNI"
                    type="text"
                    placeholder="22.334.455"
                    value={DNI}
                    setValue={handleChange}
                    image={IconDNI}
                />
                <Button
                    type="primary"
                    color="blue"
                    text="Continuar"
                    onClick={handleSubmit}
                />
            </Card>
        </Layout>
    );
}
