import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../Context";
// Assets
import { IconUser, IconMail } from "../../assets";
// Components
import { Button, Card, Input, Layout, Spinner } from "../../components";
// Services
import {
    connectionIns,
    notificatorSend,
    rolGet,
    servicioGet,
} from "../../services";
import { v4 as uuidv4 } from "uuid";

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function NoUsuario() {
    const navigate = useNavigate();
    const { merchant, setToastInfo } = useContext(GlobalContext);

    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [servicios, setServicios] = useState([]);
    const [servicio, setServicio] = useState("");
    const [roles, setRoles] = useState([]);
    const [rol, setRol] = useState("");

    const params = new URLSearchParams(window.location.search);
    const dni = params.get("dni");

    const handleError = useCallback(
        (text) => {
            setToastInfo({ open: true, text });
            setLoading(false);
        },
        [setToastInfo]
    );

    useEffect(() => {
        // Recuperar servicios
        (async () => {
            setLoading(true);
            const { status, result } = await servicioGet();
            if (status.code === 0) return handleError(status.errmsg);
            const servicios = result.map((s) => ({
                value: s.servicioId.toString(),
                name: s.servicioNombre,
            }));
            setServicios(servicios);
            setServicio(servicios[0].value);
            setLoading(false);
        })();
    }, [handleError]);

    useEffect(() => {
        // Recuperar roles
        (async () => {
            if (!servicio) return;
            setRoles([]);
            setRol("");
            const { status, result } = await rolGet(parseInt(servicio));
            if (status.code === 0) return handleError(status.errmsg);
            const roles = result.map((rol) => ({
                value: rol.rolId.toString(),
                name: rol.rolNombre,
                servicio: rol.servicioId,
            }));
            setRoles(roles);
            setRol(roles[0].value);
        })();
    }, [servicio, handleError]);

    const uuid = useMemo(() => uuidv4(), []);

    const handleSubmit = async () => {
        if (!merchant) return;
        if (!nombre) return handleError("Por favor ingrese nombre y apellido");
        if (!email || !emailRegex.test(email))
            return handleError("Por favor ingrese un mail valido");
        if (!servicio) return handleError("Por favor seleccione un servicio");
        if (!rol) return handleError("Por favor seleccione un rol");
        setLoading(true);
        const response = await connectionIns(
            uuid,
            merchant.merchantId,
            null,
            dni,
            "C",
            parseInt(rol),
            parseInt(servicio)
        );
        if (response.code === 0) return handleError(response.errmsg);
        notificatorSend(email);
        return navigate("/user-connection/qr");
    };

    if (loading)
        return (
            <Layout>
                <Card color="blue">
                    <Spinner />
                </Card>
            </Layout>
        );

    return (
        <Layout>
            <Card color="blue">
                <div className="paso">2</div>
                <h2>User Connection</h2>
                <p className="texto connection nousuario">
                    Completá los siguientes datos, le enviaremos a tu invitado
                    un QR de descarga de la app para que realice el onboarding y
                    disfrute la experiencia MIA.
                </p>
                <Input
                    name="nombre"
                    label="Nombre y Apellido"
                    type="text"
                    placeholder="Juan Galvan"
                    value={nombre}
                    setValue={setNombre}
                    image={IconUser}
                />
                <Input
                    name="email"
                    label="Mail"
                    type="text"
                    placeholder="ejemplo@gmail.com"
                    value={email}
                    setValue={setEmail}
                    image={IconMail}
                />
                <Input
                    name="servicio"
                    label="Servicio"
                    type="select"
                    value={servicio}
                    setValue={setServicio}
                    image={IconMail}
                    options={servicios}
                />
                <Input
                    name="rol"
                    label="Rol"
                    type="select"
                    value={rol}
                    setValue={setRol}
                    image={IconMail}
                    options={roles}
                />
                <Button
                    type="primary"
                    color="blue"
                    text="Continuar"
                    onClick={handleSubmit}
                />
            </Card>
        </Layout>
    );
}
