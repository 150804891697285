import React from "react";
import { Link } from "react-router-dom";
import { AnimationUserConnection } from "../../assets/animations";
import { Button, Card, Layout } from "../../components";

export default function Home() {
    return (
        <Layout video={AnimationUserConnection} videoClass="connection">
            <Card color="blue">
                <h2>User Connection</h2>
                <p className="texto connection home">
                    Experimentá un nueva forma de validar la identidad de tus
                    clientes, brindándoles una experiencia simple con soluciones
                    de autenticación multifactor, sin contraseñas.
                    <br />
                    Elimina pagos fraudulentos, carritos abandonados, y
                    amenazas, con toda la seguridad e innovación de MIA.
                </p>
                <Button type="primary" color="blue">
                    <Link to="/user-connection/paso-1">Comenzar</Link>
                </Button>
            </Card>
        </Layout>
    );
}
